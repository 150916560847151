import { useTranslation } from "react-i18next";
import "./index.scss";

const Info = () => {
  const { t } = useTranslation();

  return (
    <div className="info">
      <h4 className="h4 info__heading">{t("welcome")}</h4>
      <p className="body-small info__desc">{t("digitalIDs")}</p>
    </div>
  );
};

export default Info;
