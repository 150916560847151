import ResetPasswordCard from "../components/ResetPasswordCard";
import {Container} from "@mui/material";

const ResetPasswordPage = () => {

    return     <Container
    maxWidth="xl"
    sx={{
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: "448px"
    }}>
        <ResetPasswordCard/>
    </Container>
  

}

export default ResetPasswordPage;