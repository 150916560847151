import { Navigate, createBrowserRouter } from "react-router-dom";

import DashboardPage from "../pages/dashboard";
import ImportProductsPage from "../pages/import-products";
import LoginPage from "../pages/login";
import SettingsPage from "../pages/settings";
import TypographyPage from "../pages/typography";
import AccountSettingsPage from "../pages/account-settings";
import CompanySettings from "../components/CompanySettings";
import Members from "../components/Members";
import Billing from "../components/Billing";
import StyleListPage from "../pages/style-list";
import StyleDetailPage from "../pages/style-details";
import DataInsights from "../components/DataInsights";
import DetailedInformation from "../components/DetailedInformation";
import DigitalIDsPage from "../pages/digital-ids";
import DigitalIDPage from "../pages/digital-id";
import RegisterPage from "../pages/register";
import ResetPasswordPage from "../pages/resetpassword";
import ForgotPaswordPage from "../pages/forgotpassword";
import VerifyEmailPage from "../pages/verifyemail";
import TwoStepVerificationPage from "../pages/twostepverification";

const unauthenticatedRoutes = [
  {
    path: "",
    element: <LoginPage />,
  },
  {
    path: "register",
    element: <RegisterPage />,
  },
  {
    path: "resetpassword",
    element: <ResetPasswordPage />,
  },
  {
    path: "forgotpassword",
    element: <ForgotPaswordPage />,
  },
  {
    path: "verifyemail",
    element: <VerifyEmailPage email="pasan@gmail.com" />,
  },
  {
    path: "*",
    element: <Navigate to="/" replace={true} />,
  },
];

const routes = [
  {
    path: "dashboard",
    element: <DashboardPage />,
  },
  {
    path: "import-products",
    element: <ImportProductsPage />,
  },
  {
    path: "styles",
    element: <StyleListPage />,
  },
  {
    path: "styles/:id",
    element: <StyleDetailPage/>,
    children: [
      {
        path: "",
        element: <DetailedInformation />,
      },
      {
        path: "data",
        element: <DataInsights />,
      },
    ],
  },
  {
    path: "styles/:id/ids",
    element: <DigitalIDsPage />,
  },
  {
    path: "styles/:id/ids/:digitalID",
    element: <DigitalIDPage />,
  },
  {
    path: "company-settings",
    element: <SettingsPage />,
    children: [
      {
        path: "company",
        element: <CompanySettings />,
      },
      {
        path: "members",
        element: <Members />,
      },
      {
        path: "billing",
        element: <Billing />,
      },
    ],
  },
  {
    path: "account-settings",
    element: <AccountSettingsPage />,
  },
  {
    path: "typography",
    element: <TypographyPage />,
  },
  {
    path: "twostepverify",
    element: <TwoStepVerificationPage />,
  },
  {
    path: "*",
    element: <Navigate to="/dashboard" replace={true} />,
  },
];

export const unauthenticatedRouter = createBrowserRouter(unauthenticatedRoutes);

const router = createBrowserRouter(routes);

export default router;
