import { collection, addDoc, updateDoc, doc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import { fs, db } from "../firebase/firebase.config";
import { getEncoded } from "./base64";

const functions = getFunctions();
const inviteMember = httpsCallable(functions, "inviteMember");
const COLLECTION_USERS = "users";

export const addUserToDatabase = async (userToCreate) => {
  try {
    const encodedOrganizationId = getEncoded(userToCreate.organizationId);
    const encodedCompanyName = getEncoded(userToCreate.companyName);
    const encodedCompanyEmail = getEncoded(userToCreate.companyEmail);
    const userRef = await addDoc(
      collection(fs, COLLECTION_USERS),
      userToCreate
    );
    inviteMember({
      toEmail: userToCreate.companyEmail,
      inviteLink: `${
        process.env.REACT_APP_API_URL
      }register?joinTeam=true&id=${getEncoded(
        userRef.id
      )}&email=${encodedCompanyEmail}&role=${
        userToCreate.role
      }&organizationId=${encodedOrganizationId}&company=${encodedCompanyName}&country=${
        userToCreate.country
      }`,
    });
    return true;
  } catch (error) {
    return false;
  }
};

export const updateUser = async (id, newUser) => {
  try {
    const docRef = doc(fs, COLLECTION_USERS, id);
    await updateDoc(docRef, newUser);
    return true;
  } catch (error) {
    return false;
  }
};

export const getUserFromDB = async (id) => {
  try {
    const query = db.collection(COLLECTION_USERS).where("userId", "==", id);
    const userDoc = await query.get();
    return userDoc.docs[0].data();
  } catch (error) {
    return false;
  }
};
