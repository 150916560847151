import React from "react";
import AverageClicks from "../components/averageClick";
import PieChartComponent from "../../../Charts/Pie";
import BarChartComponent from "../../../Charts/Bar";
import {
  ChartsWrapper,
  EngagementPerPageChartsContainer,
  FullWidthChartWrapper,
  FullWidthChartsContainer,
  SingleChartWrapper,
} from "../dataInsight.styles";
import { CHARTCOLORS } from "src/stitches.config";

const clicksData = {
  labels: ["Clicked at least 1x", "Did not click"],
  datasets: [
    {
      label: "%",
      data: [62, 38],
      backgroundColor: [CHARTCOLORS[7], CHARTCOLORS[0]],
      borderColor: ["white"],
      borderWidth: 1,
    },
  ],
};

const clicksDistributionData = {
  labels: ["1x", "2x", "3x", "4-6x", "7-10x", ">10x", ">20x"],
  datasets: [
    {
      label: "% Scans",
      data: [13, 19, 17, 5, 22, 9, 19],
      backgroundColor: [
        CHARTCOLORS[0],
        CHARTCOLORS[1],
        CHARTCOLORS[2],
        CHARTCOLORS[3],
        CHARTCOLORS[4],
        CHARTCOLORS[5],
        CHARTCOLORS[6],
      ],
      borderColor: ["white"],
      borderWidth: 1,
    },
  ],
};

const frequencyOfClicksData = {
  labels: ["0", "1", "2", "3", "4", "5", "6", "7", "8"],
  datasets: [
    {
      label: "% Scans",
      data: [250, 10, 150, 322, 22, 65, 21, 102, 20],
      backgroundColor: [CHARTCOLORS[0]],
      borderColor: ["white"],
      borderWidth: 1,
    },
  ],
};

const frequencyOfClicksOptions = {
  scales: {
    x: {
      title: {
        display: true,
        text: "Weeks Since Bought",
      },
    },
    y: {
      title: {
        display: true,
        text: "User Clicks",
      },
    },
  },
};

const CarePage = () => {
  return (
    <EngagementPerPageChartsContainer>
      <AverageClicks pageName="Care Page" avgClicks="2,5" />

      <ChartsWrapper>
        <SingleChartWrapper>
          <p>{"Care Clicks"}</p>
          <PieChartComponent data={clicksData} />
        </SingleChartWrapper>
        <SingleChartWrapper>
          <p>{"Distribution of no. of Clicks(of 62% who scanned)"}</p>
          <PieChartComponent data={clicksDistributionData} />
        </SingleChartWrapper>
      </ChartsWrapper>

      <FullWidthChartsContainer>
        <FullWidthChartWrapper>
          <p>{"Frequency of Care Clicks(per week since bought)"}</p>
          <BarChartComponent
            data={frequencyOfClicksData}
            options={frequencyOfClicksOptions}
          />
        </FullWidthChartWrapper>
      </FullWidthChartsContainer>
    </EngagementPerPageChartsContainer>
  );
};

export default CarePage;
