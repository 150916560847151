import { useTranslation } from "react-i18next";
import TemplatePage from "./page";
import StyleListContent from "../components/StyleListContent";

const StyleListPage = () => {
  const { t } = useTranslation();

  return (
    <TemplatePage className="style-list-page" title={t("styles")}>
      <div className="style-list-content">
        <StyleListContent />
      </div>
    </TemplatePage>
  );
};

export default StyleListPage;
