import { useTranslation } from "react-i18next";
import HelpCard from "../components/HelpCard";
import Info from "../components/Info";
import Onboarding from "../components/Onboarding";
import TemplatePage from "./page";

const DashboardPage = () => {
  const { t } = useTranslation();

  return (
    <TemplatePage className="dashboard-page" title={t("dashboard")}>
      <div className="dashboard-content">
        <Info />
        <Onboarding />
        <HelpCard />
      </div>
    </TemplatePage>
  );
};

export default DashboardPage;
