import React, { createContext, useContext, useState } from "react";

export const StylesContext = createContext();

export const useStylesContext = () => useContext(StylesContext);

export const StylesContextProvider = ({ children }) => {
  const [styles, setStyles] = useState([]);

  const saveStyles = (Styles) => {
    setStyles(Styles);
  };

  const getStyle = (id) => {
    const style = styles.find((s) => s.id === id);
    return style;
  };

  return (
    <StylesContext.Provider value={{ styles, saveStyles, getStyle }}>
      {children}
    </StylesContext.Provider>
  );
};
