import { useEffect, useRef, useState } from "react";
import { Modal, Box, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useUserContext } from "../../store/userContext";
import { useTranslation } from "react-i18next";
import MadiInputField from "../commons/inputField/inputField";
import { yupResolver } from "@hookform/resolvers/yup";
import MadiButton from "../commons/button";
import { ErrorText } from "../commons/common.styles";
import UploadImage from "./uploadImage.js";
import { addStyleToDatabase, generateDigitalIds } from "../../services/styles";
import { showSuccessNotification } from "../Notifications/success";
import { showErrorNotification } from "../Notifications/error";
import DropDown from "../commons/dropDown/dropDown";
import { getGenders, getSizeScaling, getSizes } from "../../services/constants";

const AddStyleModal = (props) => {
  const uploadImageRef = useRef();
  const { t } = useTranslation();
  const { getUser } = useUserContext();
  const [disableButton, setDisableButton] = useState(false);
  const [open, setOpen] = useState(props.isAddStyleModalOpen);
  const detailedInformation = t("detailedInformation", { returnObjects: true });
  const errorMessages = t("errorMessages", { returnObjects: true });

  const {
    sku,
    addQuantity,
    season,
    styleName,
    styleNumber,
    gtin,
    gender,
    addStyle,
    release,
    color,
    fabric,
    sizeScale,
    size,
    market,
    retail,
    parentColor,
    store,
    pattern,
    cost,
  } = detailedInformation;
  const { skuRequired } = errorMessages;

  const stylesSchema = yup
    .object({
      // sku: yup.string().required(skuRequired),
    })
    .required();

  const methods = useForm({
    resolver: yupResolver(stylesSchema),
    defaultValues: {
      addQuantity: 0,
    },
  });
  const {
    reset,
    register,
    setValue,
    watch,
    formState: { errors },
    handleSubmit,
  } = methods;

  useEffect(() => {
    setOpen(props.isAddStyleModalOpen);
  }, [props.isAddStyleModalOpen]);

  const handleClose = () => {
    props.setIsAddStyleModalOpen(false);
  };

  const onCreateStyleSubmit = handleSubmit(async (data) => {
    const products = generateDigitalIds(data.addQuantity, "AMA");
    setDisableButton(true);

    const imageResponse = await uploadImageRef.current.uploadImageToStorage();
    if (imageResponse !== "failed") {
      const currentUser = getUser();
      const response = await addStyleToDatabase({
        organizationId: currentUser.organizationId,
        sku: data.sku,
        gtin: data.gtin,
        styleName: data.styleName,
        styleNumber: data.styleNumber,
        season: data.season,
        pattern: data.pattern,
        quantity: data.addQuantity,
        releaseDate: new Date(),
        color: data.color,
        gender: data.gender,
        cost: data.cost,
        retailPrice: data.retailPrice,
        fabricComposition: data.fabricComposition,
        size: data.size,
        sizeScale: data.sizeScale,
        market: data.market,
        imageUrl: imageResponse,
        products,
      });
      setDisableButton(false);
      if (response) {
        props.setIsAddStyleModalOpen(false);
        showSuccessNotification("Successfully created style.");
      } else {
        props.setIsAddStyleModalOpen(false);
        showErrorNotification("Failed to create style.");
      }
    } else {
      setDisableButton(false);
      props.setIsAddStyleModalOpen(false);
      showErrorNotification("Failed to create style.");
    }
  });
  return (
    <>
      <Modal
        open={open}
        onClose={() => handleClose()}
        className="madi__modal"
        style={{ paddingTop: "300px", overflow: "auto" }}
      >
        <Box className="madi__modal__content">
          <div className="close-section">
            <img src="/assets/icons/close.svg" alt="" onClick={handleClose} />
          </div>
          <h5 className="h5">{addStyle}</h5>
          <UploadImage ref={uploadImageRef} />

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Box mb={3}>
                <MadiInputField label={sku}>
                  <input
                    type="text"
                    className={`madi-text-field style-search`}
                    required={true}
                    {...register("sku")}
                  />
                </MadiInputField>
                <ErrorText>{errors.sku?.message}</ErrorText>
              </Box>

              <Box mb={3}>
                <MadiInputField label={addQuantity}>
                  <input
                    type="number"
                    min={0}
                    className={`madi-text-field style-search`}
                    required={true}
                    {...register("addQuantity")}
                  />
                </MadiInputField>
                <ErrorText>{errors.addQuantity?.message}</ErrorText>
              </Box>

              <Box mb={3}>
                <MadiInputField label={styleName}>
                  <input
                    type="text"
                    className={`madi-text-field style-search`}
                    required={true}
                    {...register("styleName")}
                  />
                </MadiInputField>
                <ErrorText>{errors.styleName?.message}</ErrorText>
              </Box>

              <Box mb={3}>
                <DropDown
                  label={gender}
                  id={"Gender"}
                  options={getGenders()}
                  {...register("gender")}
                />
              </Box>

              <Box mb={3}>
                <MadiInputField className="style-search" label={color}>
                  <input
                    type="text"
                    className={`madi-text-field style-search`}
                    required={true}
                    {...register("color")}
                  />
                </MadiInputField>
                <ErrorText>{errors.color?.message}</ErrorText>
              </Box>

              <Box mb={3}>
                <MadiInputField className="style-search" label={retail}>
                  <input
                    type="text"
                    className={`madi-text-field style-search`}
                    required={true}
                    {...register("retailPrice")}
                  />
                </MadiInputField>
                <ErrorText>{errors.retailPrice?.message}</ErrorText>
              </Box>
              <Box mb={3}>
                <DropDown
                  label={sizeScale}
                  id={"sizeScale"}
                  options={getSizeScaling()}
                  {...register("sizeScale")}
                />
              </Box>
              <Box mb={3}>
                <DropDown
                  label={size}
                  id={"size"}
                  options={getSizes(watch("sizeScale"))}
                  {...register("size")}
                />
              </Box>
              <Box mb={3}>
                <MadiInputField className="style-search" label={market}>
                  <input
                    type="text"
                    className={`madi-text-field style-search`}
                    required={true}
                    {...register("market")}
                  />
                </MadiInputField>
                <ErrorText>{errors.market?.message}</ErrorText>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box mb={3}>
                <MadiInputField className="style-search" label={gtin}>
                  <input
                    type="text"
                    className={`madi-text-field style-search`}
                    required={true}
                    {...register("gtin")}
                  />
                </MadiInputField>
                <ErrorText>{errors.gtin?.message}</ErrorText>
              </Box>

              <Box mb={3}>
                <MadiInputField className="style-search" label={season}>
                  <input
                    type="text"
                    className={`madi-text-field style-search`}
                    required={true}
                    {...register("season")}
                  />
                </MadiInputField>
                <ErrorText>{errors.season?.message}</ErrorText>
              </Box>

              <Box mb={3}>
                <MadiInputField className="style-search" label={styleNumber}>
                  <input
                    type="text"
                    className={`madi-text-field style-search`}
                    required={true}
                    {...register("styleNumber")}
                  />
                </MadiInputField>
                <ErrorText>{errors.styleNumber?.message}</ErrorText>
              </Box>

              <Box mb={3}>
                <MadiInputField className="style-search" label={release}>
                  <input
                    type="text"
                    className={`madi-text-field style-search`}
                    required={true}
                    {...register("releaseDate")}
                  />
                </MadiInputField>
                <ErrorText>{errors.releaseDate?.message}</ErrorText>
              </Box>

              <Box mb={3}>
                <MadiInputField className="style-search" label={fabric}>
                  <input
                    type="text"
                    className={`madi-text-field style-search`}
                    required={true}
                    {...register("fabricComposition")}
                  />
                </MadiInputField>
                <ErrorText>{errors.fabricComposition?.message}</ErrorText>
              </Box>
              <Box mb={3}>
                <MadiInputField className="style-search" label={parentColor}>
                  <input
                    type="text"
                    className={`madi-text-field style-search`}
                    required={true}
                    {...register("parentColor")}
                  />
                </MadiInputField>
                <ErrorText>{errors.parentColor?.message}</ErrorText>
              </Box>
              <Box mb={3}>
                <MadiInputField className="style-search" label={cost}>
                  <input
                    type="text"
                    className={`madi-text-field style-search`}
                    required={true}
                    {...register("cost")}
                  />
                </MadiInputField>
                <ErrorText>{errors.cost?.message}</ErrorText>
              </Box>
              <Box mb={3}>
                <MadiInputField className="style-search" label={store}>
                  <input
                    type="text"
                    className={`madi-text-field style-search`}
                    required={true}
                    {...register("store")}
                  />
                </MadiInputField>
                <ErrorText>{errors.store?.message}</ErrorText>
              </Box>
              <Box mb={3}>
                <MadiInputField className="style-search" label={pattern}>
                  <input
                    type="text"
                    className={`madi-text-field style-search`}
                    required={true}
                    {...register("pattern")}
                  />
                </MadiInputField>
                <ErrorText>{errors.pattern?.message}</ErrorText>
              </Box>
            </Grid>
          </Grid>
          <MadiButton
            disabled={disableButton}
            label={addStyle}
            type="primary"
            onClick={onCreateStyleSubmit}
          />
        </Box>
      </Modal>
    </>
  );
};

export default AddStyleModal;
