import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import './index.scss';

const MadiPasswordField = ( props ) => {

    const { t } = useTranslation();
    const { value, tKey, onChange, required = true, className = ''} = props;
    const translatedObject = t(tKey, { returnObjects: true });

    const handleOnChange = (event) => {
      const { name, value } = event.target;
      if(onChange) {
        onChange(name, value)
      }
    }

    return <div className="text-field">
      <div className="input-block">
        
        <input
          type='password'
          required={required}
          value={value}
          className={`madi-text-field ${className}`}
          onChange={handleOnChange}
          name={tKey}
          id={tKey}
        />
        <label className='placeholder'>
          {translatedObject.fieldLabel}
        </label>
      </div>
    </div>
}

MadiPasswordField.propTypes = {
  value: PropTypes.string,
  tKey: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
  onChange: PropTypes.func
}

export default MadiPasswordField;