import { NavLink } from "react-router-dom";

const ICON_ROOT_PATH = '/assets/icons';

const SideBarItem = ( { icon, label, url } ) => {

    return <div className='item'>
        <img className='sidebar-icon' src={`${ICON_ROOT_PATH}/${icon}`} alt='' />
        <NavLink
            to={url}
            className={({ isActive }) => isActive ? "link-item active" : "link-item" }
        >
            { label }
        </NavLink>
    </div>
}

export default SideBarItem;