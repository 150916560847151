import React, { useState } from "react";
import {
  ChartSectionContainer,
  ChartSectionHeading,
  ChartsWrapper,
  EngagementPerPageHeadingWrapper,
  ScanNumberContainer,
  SingleChartWrapper,
  TimeEngagementMainTextWrapper,
  TimeEngagementPerPageContainer,
  TimeEngagementPerPageWrapper,
  TimeEngagementWrapper,
} from "./dataInsight.styles";
import ScanNumbers from "./components/scanNumbers";
import CountryChart from "./chartsSections/country";
import FrequencyChart from "./chartsSections/frequency";
import DidScannedChart from "./chartsSections/didScaned";
import DistributionOfScansChart from "./chartsSections/distributionOfScans";
import FilterItem from "src/components/StyleListContent/filter-item";
import { CAREPAGE, RESALEAGE, getPages } from "src/services/constants";
import CarePage from "./chartsSections/carePage";
import ResalePage from "./chartsSections/resalePage";
const DataInsight = () => {
  const [selectedEngagementPage, setSelectedEngagementPage] = useState("");
  return (
    <>
      <ScanNumberContainer>
        <ScanNumbers description="Total Scans" total="1457" />
        <ScanNumbers description="Avarge scans per uniqe ID" total="1.45" />
      </ScanNumberContainer>

      <ChartSectionContainer>
        <ChartSectionHeading>Digital ID Usage</ChartSectionHeading>
        <ChartsWrapper>
          <SingleChartWrapper>
            <p>{"Amt Who Have Scanned >1x"}</p>
            <DidScannedChart />
          </SingleChartWrapper>
          <SingleChartWrapper>
            <p>{"Distribution of no. of Scans(of 62% who scanned)"}</p>
            <DistributionOfScansChart />
          </SingleChartWrapper>
        </ChartsWrapper>
      </ChartSectionContainer>

      <ChartSectionContainer>
        <ChartSectionHeading>Country</ChartSectionHeading>
        <ChartsWrapper>
          <SingleChartWrapper>
            <p>{"Scans by Country (%)"}</p>
            <CountryChart />
          </SingleChartWrapper>
          <SingleChartWrapper></SingleChartWrapper>
        </ChartsWrapper>
      </ChartSectionContainer>

      <ChartSectionContainer>
        <ChartSectionHeading>Time Engagement</ChartSectionHeading>
        <ChartsWrapper>
          <TimeEngagementWrapper>
            <TimeEngagementMainTextWrapper>
              <h5>0m,00s</h5>
              <p>Avg. per user</p>
            </TimeEngagementMainTextWrapper>
            <TimeEngagementPerPageContainer>
              <h5>Per page</h5>
              <TimeEngagementPerPageWrapper>
                <p>Home Screen</p>
                <p>0m.00s</p>
              </TimeEngagementPerPageWrapper>
              <TimeEngagementPerPageWrapper>
                <p>Origin Story</p>
                <p>0m.00s</p>
              </TimeEngagementPerPageWrapper>
              <TimeEngagementPerPageWrapper>
                <p>Fabric Information</p>
                <p>0m.00s</p>
              </TimeEngagementPerPageWrapper>
              <TimeEngagementPerPageWrapper>
                <p>Returns/ Warranty </p>
                <p>0m.00s</p>
              </TimeEngagementPerPageWrapper>
              <TimeEngagementPerPageWrapper>
                <p>Repair/ Alterations</p>
                <p>0m.00s</p>
              </TimeEngagementPerPageWrapper>
              <TimeEngagementPerPageWrapper>
                <p>Resale </p>
                <p>0m.00s</p>
              </TimeEngagementPerPageWrapper>
              <TimeEngagementPerPageWrapper>
                <p>Upcycle </p>
                <p>0m.00s</p>
              </TimeEngagementPerPageWrapper>
            </TimeEngagementPerPageContainer>
          </TimeEngagementWrapper>
          <SingleChartWrapper>
            <p>{"Frequency of Scans(avg. per scan)"}</p>
            <FrequencyChart />
          </SingleChartWrapper>
        </ChartsWrapper>
      </ChartSectionContainer>
      <ChartSectionContainer>
        <EngagementPerPageHeadingWrapper>
          <div style={{ flexBasis: "30%" }}>
            <ChartSectionHeading>Engagement by Page</ChartSectionHeading>
          </div>
          <div style={{ flexBasis: "70%" }}>
            <FilterItem
              type={"Choose Page"}
              options={getPages()}
              onSelectOption={(value: string) =>
                setSelectedEngagementPage(value)
              }
            />
          </div>
        </EngagementPerPageHeadingWrapper>
        {selectedEngagementPage === CAREPAGE && <CarePage />}
        {selectedEngagementPage === RESALEAGE && <ResalePage />}
      </ChartSectionContainer>
    </>
  );
};

export default DataInsight;
