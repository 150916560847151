
import { useState } from "react";
import { useTranslation } from "react-i18next";
import MadiButton from "../commons/button";
import MadiTextField from "../commons/text-field";
import AddStyleModal from "../AddStyles";

const Actions = (props) => {
  const { totalProducts } = props;
  const [openAddStyleModal, setOpenAddStyleModal] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="actions">
      <div className="actions__left">
        <h5 className="h5">
          {t("totalStyles")}: <span className="secondary">{totalProducts}</span>
        </h5>
      </div>
      <div className="actions__right">
        <MadiButton label={t("export")} type="plain" className="export">
          <img src="/assets/icons/export.svg" alt="" />
        </MadiButton>
        <MadiTextField className="style-search" tKey="search" />
        <MadiButton
          label={t("addProduct")}
          type="primary"
          onClick={() => setOpenAddStyleModal(true)}
        />
      </div>
      <AddStyleModal
        isAddStyleModalOpen={openAddStyleModal}
        setIsAddStyleModalOpen={(value) => setOpenAddStyleModal(value)}
      />
    </div>
  );
};

export default Actions;
