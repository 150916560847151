import { useTranslation } from "react-i18next";
import MadiButton from "../commons/button";
import MadiTextField from "../commons/text-field";

const Actions = ( {name} ) => {

    const { t } = useTranslation();

    return <div className="actions">
        <div className="actions__left">
            <h5 className="h5">
                { name }
            </h5>
        </div>
        <div className="actions__right">
            <MadiButton
                label={t('export')}
                type='plain'
                className='export'
            >
                <img src="/assets/icons/export.svg" alt='' />
            </MadiButton>
            <MadiTextField 
                className='style-search'
                tKey='search'
            />
        </div>
    </div>
}

export default Actions;