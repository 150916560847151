import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";

import "./index.scss";

const TabLinks = (props) => {
  const { t } = useTranslation();
  const { links = [] } = props;

  return (
    <div className="component tab-links">
      {links.map((link) => {
        const { to, key } = link;
        return (
          <NavLink
            key={key}
            to={to}
            className={({ isActive }) =>
              isActive ? "link sub-big active" : "sub-big link"
            }
          >
            {t(key)}
          </NavLink>
        );
      })}
    </div>
  );
};

TabLinks.propTypes = {
  links: PropTypes.array,
};

export default TabLinks;
