import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MadiButton from "../commons/button";

const ProductInformation = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const productInformation = t("productInformation", { returnObjects: true });

  const { detail } = props;
  const { styleName, id, quantity, imageUrl } = detail;
  const navigateToDigitalIDs = () => {
    navigate("ids");
  };
  return (
    <div className="pi-info">
      <div className="pi-container">
        <div className="pi-container__left">
          <h5 className="h5">{styleName}</h5>
          <p className="sub-small quantity">
            <span className="primary">{"MADI ID"}: </span>
            <span className="sub-small">{id}</span>
          </p>
          <div className="pi-container__left__block">
            <h5 className="h5">{t("productLabel")}</h5>
            <div className="product-info">
              {Object.keys(productInformation).map((key) => {
                const label = productInformation[key];
                const value = detail[key];

                return (
                  <div key={key} className="info-chunk">
                    <p className="sub-small primary">{label}</p>
                    <p className="sub-small">{value}</p>
                  </div>
                );
              })}
            </div>
            <div className="digital-link">
              <MadiButton
                className="primary-btn"
                label={t("browseDigitalIDs")}
                onClick={navigateToDigitalIDs}
              />
            </div>
          </div>
        </div>
        <div className="pi-container__right">
          <div className="image-holder">
            <img
              src={imageUrl}
              alt={"No Image"}
              width={"200px"}
              height={"250px"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductInformation;
