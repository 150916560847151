import MadiButton from "../commons/button";

const KEYS = ["name", "email", "level", "added", "status", "action"];

const MembersTable = (props) => {
  const { tableHeading, memberInfo, updateParams } = props;
  const { offset, elementsCount, lastPage, firstPage, totalCount, members } =
    memberInfo;

  const handleQueryParams = (factor) => {
    const page = (offset + factor * elementsCount) / elementsCount;
    updateParams(page);
  };

  return (
    <div className="member table">
      <div role="table" className="madi-table">
        <div className="thead">
          <div className="tr">
            {KEYS.map((key, index) => {
              const cbox =
                index === 0 ? (
                  <input className="cbox" type="checkbox" name="selectAll" />
                ) : (
                  ""
                );
              return (
                <p className="overline th" key={key}>
                  {" "}
                  {cbox} {tableHeading[key]}
                </p>
              );
            })}
          </div>
        </div>
        <div className="tbody">
          {members.map((member) => {
            return (
              <div className="tr" key={member["email"]}>
                {KEYS.map((key, index) => {
                  let markup;
                  const cbox =
                    index === 0 ? (
                      <input
                        className="cbox"
                        type="checkbox"
                        name="selectAll"
                      />
                    ) : (
                      ""
                    );

                  switch (key) {
                    case "action":
                      markup = (
                        <MadiButton className="action">
                          <img src="/assets/icons/actions.svg" />
                        </MadiButton>
                      );
                      break;
                    case "status":
                      markup = (
                        <MadiButton
                          className={`status ${member[key].toLowerCase()}`}
                        >
                          {" "}
                          {member[key]}{" "}
                        </MadiButton>
                      );
                      break;
                    default:
                      markup = member[key];
                  }
                  return (
                    <p className="body-small td" key={key}>
                      {" "}
                      {cbox} {markup}
                    </p>
                  );
                })}
              </div>
            );
          })}
        </div>
        <div className="table-footer">
          <div className="pagination">
            <span className="body-small">
              {" "}
              {offset + 1} - {(offset + 1) * elementsCount} of {totalCount}{" "}
            </span>
          </div>
          <div className="arrows">
            <MadiButton
              disabled={firstPage}
              onClick={() => handleQueryParams(-1)}
            >
              <img src="/assets/icons/left-arrow.svg" alt="" />
            </MadiButton>
            <MadiButton
              disabled={lastPage}
              onClick={() => handleQueryParams(1)}
            >
              <img src="/assets/icons/right-arrow.svg" alt="" />
            </MadiButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembersTable;
