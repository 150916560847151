export const optionsGenerator: any = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "left",
    },
    datalabels: {
      display: true,
      color: "white",
      formatter: (value: any) => {
        return value+'%';
      },
    },
  },
};

export const getCountryChartData = async () => {
  try {
    return true;
  } catch (error) {
    return false;
  }
};
