import { useTranslation } from "react-i18next";
import TemplatePage from "./page";
import BreadCrumb from "../components/BreadCrumb";
import StyleDetailContent from "../components/StyleDetailContent";
import { useEffect, useState } from "react";
import axios from "../services/axios";
import { getStylePageBreadCrumb } from "../utils/page-util";
import { useParams, useLocation } from "react-router-dom";
import { supabase } from "../../src/supabaseClient";
import { useStylesContext } from "../store/stylesContext";
import { formatDateToCustomFormat } from "src/services/constants";

const StyleDetailPage = (props) => {
  const location = useLocation();
  const customData = location.state?.data;
  const { t } = useTranslation();
  const { getStyle } = useStylesContext();
  let { id } = useParams();

  const [styleDetail, setStyleDetail] = useState({
    completed: true,
    data: {},
  });

  const [links, setLinks] = useState([]);
  const [product, setProduct] = useState(null);

  useEffect(() => {
    const style = getStyle(id);
    if (style.releaseDate) {
      style.release = formatDateToCustomFormat(style.releaseDate.toDate());
    }
    setProduct(style);
  }, []);

  return (
    <TemplatePage className="style-detail-page" title={t("styles")}>
      {product && (
        <div className="style-detail-content">
          {/* <BreadCrumb links={links} /> */}
          {/* <StyleDetailContent {...styleDetail.data} /> */}
          <StyleDetailContent product={product} />
        </div>
      )}
    </TemplatePage>
  );
};

export default StyleDetailPage;
