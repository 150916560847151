import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";
import "./index.scss";

const DetailedInformation = () => {
  const [detail] = useOutletContext();
  const { t } = useTranslation();
  const detailedInformation = t("detailedInformation", { returnObjects: true });

  return (
    <div className="component detailed-info">
      <div className="detailed-info__holder">
        {Object.keys(detailedInformation).map((key) => {
          const label = detailedInformation[key];
          const value = detail[key];

          return (
            <div key={key} className="info-chunk">
              <p className="sub-small primary">{label}</p>
              <p className="sub-small">{value}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DetailedInformation;
