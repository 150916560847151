import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { CHARTCOLORS } from "src/stitches.config";
import { optionsGenerator } from "src/services/charts";

ChartJS.register(ArcElement, Tooltip, Legend);

const data = {
  labels: [
    "Daily",
    "3-4x per week",
    "1x per week",
    "2-3x per month",
    "1x per month",
    "1x every 2 months",
    "< every 2 months",
  ],
  datasets: [
    {
      label: "% Scans",
      data: [13, 19, 17, 5, 22, 9, 19],
      backgroundColor: [
        CHARTCOLORS[0],
        CHARTCOLORS[1],
        CHARTCOLORS[2],
        CHARTCOLORS[3],
        CHARTCOLORS[4],
        CHARTCOLORS[5],
        CHARTCOLORS[6],
      ],
      borderColor: ["white"],
      borderWidth: 1,
    },
  ],
};



const FrequencyChart = () => {
  return (
    <Pie data={data} options={optionsGenerator} plugins={[ChartDataLabels]} />
  );
};

export default FrequencyChart;
