import { useRef } from 'react';

const ICON_ROOT_PATH = '/assets/icons';
const ICON = {
    'delogue': 'delogue.svg',
    'csv': 'csv.svg'
}

const Option = ( props ) => {
    
    const fileRef = useRef(null);

    const { type, title, subTitle, action } = props;

    const handleAction = () => {
        switch(type) {
            case 'csv':
                fileRef.current.click(); 
                break;
            default:
                break;
        }
    }

    return <div className='system-option'>
            <img src={`${ICON_ROOT_PATH}/${ICON[type]}`} alt={title} className='logo' />
            <h6 className='h6'>
                { title }
            </h6>
            <p className='body-small desc'>
                { subTitle }
            </p>
            <button className='primary-btn' onClick={ handleAction }>
                { type === 'csv' && <input ref={fileRef} id='csv-file' type='file' hidden /> }
                {action}
            </button>
    </div>
}

export default Option;