import React from "react";
import { ReactComponent as Qr } from "../../../../images/qrLight.svg";
import { ScanNumberWrapper } from "./components.styles";

const ScanNumbers = ({
  total,
  description,
}: {
  total: string;
  description: string;
}) => {
  return (
    <ScanNumberWrapper>
      <Qr />
      <div>
        <p>{total}</p>
        <p>{description}</p>
      </div>
    </ScanNumberWrapper>
  );
};

export default ScanNumbers;
