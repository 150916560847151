import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ProgressBar from "./progress";
import Card from "./card";
import axios from "../../services/axios";
import "./index.scss";
import InviteMemberModal from "./inviteMemberModal";

const AVAILABLE_TASKS = [
  { key: "invite", type: "inline" },
  { key: "import", type: "inline" },
];

const Onboarding = () => {
  const { t } = useTranslation();
  const [userTasks, setUserTasks] = useState([]);
  const [showOnBoarding, setShowOnBoarding] = useState(false);
  const [isInviteMemberModalOpen, setIsInviteMemberModalOpen] = useState(false);
  const handleInviteCardClick = () => {
    setIsInviteMemberModalOpen(true);
  };
  useEffect(() => {
    const fetchData = async () => {
      const tasks = await axios.get("/api/tasks");

      const compiledTasks = AVAILABLE_TASKS.map((taskKey) => {
        const translationObject = t(taskKey.key, { returnObjects: true });
        const filteredTasks = tasks.data.filter(
          (task) => task.id === taskKey.key
        );
        const task = filteredTasks ? filteredTasks[0] : {};
        return {
          ...translationObject,
          ...task,
          type: taskKey.type,
          actionType: taskKey.key,
          onCardClick:
            taskKey.key === "invite" ? handleInviteCardClick : () => {},
        };
      });
      const notStarted = tasks.data.filter(
        (task) => task.status === "not-started"
      );
      setShowOnBoarding(notStarted && notStarted.length > 0);
      setUserTasks(compiledTasks);
    };
    fetchData();
  }, []);

  const getOnboardingTasks = () => {
    const onboardingTasks = userTasks.map((task) => (
      <Card {...task} key={task.id} />
    ));
    return onboardingTasks;
  };

  return (
    showOnBoarding && (
      <div className="onboarding-container">
        <h5 className="h5"> {t("started")} </h5>
        <ProgressBar tasks={userTasks} totalTasks={AVAILABLE_TASKS.length} />
        <div className="boarding-cards">{getOnboardingTasks()}</div>
        <InviteMemberModal
          isInviteMemberModalOpen={isInviteMemberModalOpen}
          setIsInviteMemberModalOpen={setIsInviteMemberModalOpen}
        />
      </div>
    )
  );
};

export default Onboarding;
