import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { optionsGenerator } from "src/services/charts";

ChartJS.register(ArcElement, Tooltip, Legend);
const PieChartComponent = (props) => {
  return (
    <Pie
      data={props.data}
      options={optionsGenerator}
      plugins={[ChartDataLabels]}
    />
  );
};

export default PieChartComponent;
