import React from "react";
import { RouterProvider } from "react-router-dom";
import ReactDOM from "react-dom/client";
import axios from "axios";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { UserContextProvider } from "./store/userContext";
import { StylesContextProvider } from "./store/stylesContext";
import router from "./router";
import "./i18n";

import "./index.scss";
import App from "./App";

// Setting axios configuration
axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const theme = createTheme({
  palette: {
    primary: {
      main: "#9a3b34", // This is an orange looking color
    },
  }, // as an aside, highly recommend importing roboto font for Material UI projects! Looks really nice
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <UserContextProvider>
    <StylesContextProvider>
      <ThemeProvider theme={theme}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </ThemeProvider>
    </StylesContextProvider>
  </UserContextProvider>
);
