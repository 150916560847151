import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { CHARTCOLORS } from "src/stitches.config";
import { optionsGenerator } from "src/services/charts";

ChartJS.register(ArcElement, Tooltip, Legend);

const data = {
  labels: ["Scanned at least 1x", "Did not scan"],
  datasets: [
    {
      label: "%",
      data: [62, 38],
      backgroundColor: [CHARTCOLORS[7], CHARTCOLORS[0]],
      borderColor: ["white"],
      borderWidth: 1,
    },
  ],
};

const DidScannedChart = () => {
  return (
    <>
      <Pie data={data} options={optionsGenerator} plugins={[ChartDataLabels]} />
    </>
  );
};

export default DidScannedChart;
