import { Link } from "react-router-dom";
import MadiButton from "../commons/button";

const Card = (props) => {
  const { heading, description, action, status, path, type, onCardClick } =
    props;

  return (
    <div className="boarding">
      <div className="boarding__card">
        <div className="block__left">
          <div className={`img__content ${status}`}>
            <div className="check"></div>
          </div>
          <div className="text__content">
            <h6 className="h6">{heading}</h6>
            <p className="sub-big">{description}</p>
          </div>
        </div>
        <div className="block__right">
          <div className="cta-action">
            {type === "inline" ? (
              <MadiButton onClick={onCardClick} className="primary-link">
                {action}
              </MadiButton>
            ) : (
              <Link to={path} className="primary-link">
                {action}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
