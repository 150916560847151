import PropTypes from "prop-types";

const MadiButton = (props) => {
  const {
    label,
    type = "custom",
    className = "",
    onClick,
    disabled = false,
  } = props;

  return (
    <div className="madi-button">
      <button
        className={`btn ${className} ${type}-btn`}
        onClick={onClick}
        disabled={disabled}
      >
        {props.children ? props.children : ""}
        {label}
      </button>
    </div>
  );
};

MadiButton.propTypes = {
  label: PropTypes.string,
  type: PropTypes.oneOf(["primary", "secondary", "danger", "plain"]),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default MadiButton;
