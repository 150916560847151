import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import MadiTextField from "../commons/text-field";
import { useState } from "react";

const InviteMember = (props) => {
  const [ddValue, setDDValue] = useState("");
  const [email, setEmailValue] = useState("");

  const handleTextChange = (_, value) => {
    setEmailValue(value);
    props.populateData("email", value);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setDDValue(value);
    props.populateData("role", value);
  };

  return (
    <div className="invite-member">
      <MadiTextField
        tKey="invite-member"
        onChange={handleTextChange}
        value={email}
      />
      <FormControl fullWidth>
        <InputLabel id="role-dd">Role</InputLabel>
        <Select
          onChange={handleChange}
          value={ddValue}
          id="role-dd"
          label="Role"
          sx={{
            borderRadius: "12px",
            height: "40px",
            "&:hover": {},
          }}
        >
          <MenuItem value="admin">ADMIN</MenuItem>
          <MenuItem value="teamMember">TEAM MEMBER</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default InviteMember;
