import { Link } from "react-router-dom";
import MadiButton from "../commons/button";

const KEYS = [
  "img",
  "style",
  "sku",
  "category",
  "quantity",
  "season",
  "options",
];

const StyleTable = (props) => {
  const { tableHeading, styleListData, updateParams, productList } = props;
  return (
    <div className="member table">
      {productList && (
        <div role="table" className="madi-table">
          <div className="thead">
            <div className="tr">
              {KEYS.map((key, index) => {
                const cbox =
                  index === 0 ? (
                    <input className="cbox" type="checkbox" name="selectAll" />
                  ) : (
                    ""
                  );
                return (
                  <p className="overline th" key={key}>
                    {" "}
                    {cbox} {tableHeading[key]}
                  </p>
                );
              })}
            </div>
          </div>
          <div className="tbody">
            {productList.map((member, oIndex) => {
              return (
                <div className="tr" key={oIndex}>
                  {KEYS.map((key, index) => {
                    let markup;
                    const cbox =
                      index === 0 ? (
                        <input
                          className="cbox"
                          type="checkbox"
                          name="selectAll"
                        />
                      ) : (
                        ""
                      );
                    const value = member[key];
                    switch (key) {
                      case "options":
                        markup = (
                          <MadiButton className="action">
                            <img src="/assets/icons/actions.svg" />
                          </MadiButton>
                        );
                        break;
                      case "img":
                        markup = (
                          <div className="image-container">
                            <img
                              src={member["imageUrl"]}
                              alt={"No Image"}
                              width={"40px"}
                              height={"40px"}
                            />
                          </div>
                        );
                        break;

                      case "style":
                        markup = (
                          <Link
                            to={`/styles/${member["id"]}`}
                            className="text-link"
                          >
                            <p>{member["styleName"]}</p>
                          </Link>
                        );
                        break;
                      case "sku":
                        markup = (
                          <Link
                            to={`/styles/${member["id"]}`}
                            className="text-link"
                          >
                            {" "}
                            {value}{" "}
                          </Link>
                        );
                        break;
                      case "category":
                        markup = (
                          <Link
                            to={`/styles/${member["id"]}`}
                            className="text-link"
                          >
                            {" "}
                            {value}{" "}
                          </Link>
                        );
                        break;
                      case "quantity":
                        markup = (
                          <Link
                            to={`/styles/${member["id"]}`}
                            className="text-link"
                          >
                            {" "}
                            {value}{" "}
                          </Link>
                        );
                        break;
                      case "season":
                        markup = (
                          <Link
                            to={`/styles/${member["id"]}`}
                            className="text-link"
                          >
                            <span className="season"> {value} </span>
                          </Link>
                        );
                        break;
                      case "status":
                        markup = (
                          <MadiButton
                            className={`status ${value.toLowerCase()}`}
                          >
                            {" "}
                            {value}{" "}
                          </MadiButton>
                        );
                        break;

                      default:
                        markup = value;
                    }
                    return (
                      <p className="body-small td" key={key}>
                        {" "}
                        {cbox} {markup}
                      </p>
                    );
                  })}
                </div>
              );
            })}
          </div>
          <div className="table-footer">
            <div className="pagination">
              <span className="body-small"> </span>
            </div>
            <div className="arrows">
              <MadiButton>
                <img src="/assets/icons/left-arrow.svg" alt="" />
              </MadiButton>
              <MadiButton>
                <img src="/assets/icons/right-arrow.svg" alt="" />
              </MadiButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default StyleTable;
