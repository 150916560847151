import { useTranslation } from "react-i18next";
import MadiButton from "../commons/button";
import MadiPasswordField from "../commons/password-field";

const ChangePassword = () => {

    const { t } = useTranslation();
    const changePassword = t('changePassword', {returnObjects: true});
    const { title, requirements } = changePassword;

    return <div className="change-pw setting-block">
        <div className="change-pw__holder">
            <h6 className="h6">
                { title }
            </h6>
            
            <div className="fields__section">
                <div className="one-ele">
                    <MadiPasswordField
                        tKey='currentPassword'
                    />
                </div>
                <div className="two-ele">
                    <MadiPasswordField
                         tKey='newPassword'
                    />
                    <MadiPasswordField
                         tKey='confirmPassword'
                    />
                </div>
            </div>
            <div className="requirement">
                <p className="sub-small">
                    { requirements.title }
                </p>
                <ul className="requirement__values">
                    {
                        requirements.items.map( (item, index) => {
                            return <li key={index} className="requirement__values__item sub-small">
                                { item }
                            </li>
                        } )
                    }
                </ul>
            </div>
            <div className="profile__submit-container">
                <MadiButton
                    label={t('saveChanges')}
                    type='primary'
                />
                <MadiButton
                    label={t('cancel')}
                    type='secondary'
                />
            </div>
        </div>
    </div>
}

export default ChangePassword;