import React from "react";
import { Wrapper, DropDownLabel, DropDownSelect } from "./dropDown.styles";

const DropDown = React.forwardRef((props, ref) => (
  <Wrapper>
    <DropDownLabel htmlFor={props.id}>{props.label}</DropDownLabel>
    <DropDownSelect name="select" id={props.id} ref={ref} {...props}>
      {props.options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.title}
        </option>
      ))}
    </DropDownSelect>
  </Wrapper>
));

export default DropDown;
