import Search from "./search";
import "./index.scss";
import MembersTable from "./table";
import { useEffect, useState } from "react";
import axios from "../../services/axios";
import { useTranslation } from "react-i18next";

const Members = () => {
  const { t } = useTranslation();
  const tableHeading = t("membersTable", { returnObjects: true });

  const [members, setMembers] = useState({
    completed: false,
    data: {},
  });
  const [queryParams, setQueryParams] = useState({
    page: 0,
    search: "",
  });

  const updateQueryParams = (page, search = "") => {
    setQueryParams({
      page,
      search,
    });
  };

  const makeApiCall = () => {
    axios.get("/api/members").then((data) => {
      setMembers({
        completed: true,
        data,
      });
    });
  };

  useEffect(() => {
    makeApiCall();
  }, [queryParams]);

  return (
    <div className="members">
      <Search />
      {members.completed && (
        <MembersTable
          tableHeading={tableHeading}
          memberInfo={members.data}
          updateParams={updateQueryParams}
        />
      )}
    </div>
  );
};

export default Members;
