import { Link } from "react-router-dom";
import Typography from "../components/Typography";

const TypographyPage = () => {
    return <div className="page typography">
        <Typography />
        <div className="links">
            <Link className="link" to='/'>Home</Link>
        </div>
    </div>
}

export default TypographyPage;