import { useState } from "react";
import { useTranslation } from "react-i18next";
import MadiButton from "../commons/button";
import MadiTextField from "../commons/text-field";
import forms from './forms.json';

const Profile = (props) => {

    const { t } = useTranslation();

    const [ accountInfo, setAccountInfo ] = useState(props);
    
    const { 
        avatar,
        firstName
    } = accountInfo;

    const handleOnChange = (name, value) => {
        setAccountInfo(name, value);
    }

    return <div className='profile setting-block'>
        <div className="profile__image-container">
            <div className="left-block">
                <img src={avatar} alt={firstName} />
            </div>
            <div className="right-block">
                <div className="buttons">
                    <MadiButton
                        label={t('uploadPhoto')}
                        type='primary'
                    />
                    <MadiButton
                        label={t('reset')}
                        type='secondary'
                    />
                </div>
                <div className="info">
                    <p className="body-small">{t('allowedText')}</p>
                </div>
            </div>
        </div>
        <div className="profile__form-container">
            {
                forms.elements.map( form => {
                    return <MadiTextField key={form} value={accountInfo[form]} tKey={form} onChange={ handleOnChange } />
                } )
            }
        </div>
        <div className="profile__submit-container">
            <MadiButton
                label={t('saveChanges')}
                type='primary'
            />
            <MadiButton
                label={t('cancel')}
                type='secondary'
            />
        </div>
    </div>
}

export default Profile;