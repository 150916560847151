import Login from "../components/LoginCard";
import { Container } from "@mui/material";

const LoginPage = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Login />
    </Container>
  );
};

export default LoginPage;
