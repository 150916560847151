import { useTranslation } from "react-i18next";


import Integrations from "../components/Integrations";

import TemplatePage from './page';

const DashboardPage = () => {

    const { t } = useTranslation();
 
    return <TemplatePage className='integration-page' title={t('integrations')}>
        <div className='integrations-content'>
            <Integrations systems={ t('systems', { returnObjects: true }) } />
        </div>
    </TemplatePage>
}

export default DashboardPage;