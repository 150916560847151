import PropTypes from "prop-types";

import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import Footer from "../components/Footer";

const TemplatePage = (props) => {
  const { className = "", title = "" } = props;

  return (
    <div className={`page template ${className}`}>
      <Sidebar />
      <main className="main-content">
        <Header title={title} />
        {props.children}
        <Footer />
      </main>
    </div>
  );
};

TemplatePage.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
};

export default TemplatePage;
