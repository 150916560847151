import { useEffect, useState } from "react";
import { Modal, Box } from "@mui/material";
import InviteMember from "./invite-member";
import MadiButton from "../commons/button";
import { addUserToDatabase } from "../../services/users";
import { showErrorNotification } from "../Notifications/error";
import { showSuccessNotification } from "../Notifications/success";
import { useUserContext } from "../../store/userContext";

const InviteMemberModal = (props) => {
  const { getUser } = useUserContext();
  const [open, setOpen] = useState(props.isInviteMemberModalOpen);
  const [disableButton, setDisableButton] = useState(false);

  useEffect(() => {
    setOpen(props.isInviteMemberModalOpen);
  }, [props.isInviteMemberModalOpen]);

  const [invitationData, setInvitationData] = useState({
    email: "",
    role: "",
  });

  const handleClose = () => {
    props.setIsInviteMemberModalOpen(false);
  };

  const sendInvitation = async () => {
    setDisableButton(true);
    const currentUser = getUser();
    const response = await addUserToDatabase({
      companyName: currentUser.companyName,
      country: currentUser.country,
      organizationId: currentUser.organizationId,
      companyEmail: invitationData.email,
      role: invitationData.role,
      verified: false,
    });
    setDisableButton(false);
    if (response) {
      props.setIsInviteMemberModalOpen(false);
      showSuccessNotification("Successfully created member.");
    } else {
      props.setIsInviteMemberModalOpen(false);
      showErrorNotification("Failed to create member.");
    }
  };

  const populateData = (name, value) => {
    setInvitationData({
      ...invitationData,
      [name]: value,
    });
  };

  return (
    <>
      <Modal open={open} onClose={() => handleClose()} className="madi__modal">
        <Box className="madi__modal__content">
          <div className="close-section">
            <img src="/assets/icons/close.svg" alt="" onClick={handleClose} />
          </div>
          <h5 className="h5">Invite team members</h5>
          <InviteMember populateData={populateData} />
          <div className="modal-footer">
            <MadiButton type="secondary" label="CANCEL" onClick={handleClose} />
            <MadiButton
              disabled={disableButton}
              onClick={sendInvitation}
              type="primary"
              label="SEND INVITATIONS"
            />
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default InviteMemberModal;
