import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";

const FilterItem = (props) => {
  const { t } = useTranslation();

  const { type, options, onSelectOption } = props;

  return (
    <div className="filter-item">
      <FormControl fullWidth>
        <InputLabel id={type} style={{ fontSize: "13px" }}>
          {t(type)}
        </InputLabel>
        <Select
          onChange={(e) => onSelectOption(e.target.value)}
          style={{ fontSize: "12px" }}
          id={type}
          label={t(type)}
          sx={{
            borderRadius: "12px",
            "&:hover": {},
          }}
        >
          {options.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              style={{ fontSize: "13px" }}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default FilterItem;
