import { useTranslation } from "react-i18next";
import MadiButton from "../commons/button";

const KEYS = ['type', 'action'];

const NotificationPreferences = () => {

    const { t } = useTranslation();
    const notificationPreferences = t('notificationPreferences', {returnObjects: true});
    const { title, subTitle, tableHeading, tableRows } = notificationPreferences;

    return <div className="change-pw setting-block">
        <div className="change-pw__holder">
            <h6 className="h6">
                { title }
            </h6>
            <p className="sub-small">
                { subTitle }
            </p>

            <div role="table" className="madi-table notification">
        <div className="thead">
          <div className="tr">
            {
                KEYS.map( key => {
                return <p className="overline th" key={key} > { tableHeading[key] }</p> 
                })
            }
          </div>
        </div>
        <div className="tbody">
            { tableRows.map( (member, index) => {
              return <div className="tr" key={index} >
                  {
                  KEYS.map( key => {

                    let markup;

                    switch( key ) {
                      case 'action':
                        markup = <input type="checkbox" />;
                        break;

                      default:
                        markup = member['label']
                    }
                    return <p className="body-small td" key={key} >{ markup }</p> }
                  )
                  }
              </div>
            } )}
        </div>
      </div>
            
            <div className="profile__submit-container">
                <MadiButton
                    label={t('saveChanges')}
                    type='primary'
                />
            </div>
        </div>
    </div>
}

export default NotificationPreferences;