import React from "react";
import AverageClicks from "../components/averageClick";
import PieChartComponent from "../../../Charts/Pie";
import {
  ChartsWrapper,
  EngagementPerPageChartsContainer,
  SingleChartWrapper,
} from "../dataInsight.styles";
import { CHARTCOLORS } from "src/stitches.config";

const clicksData = {
  labels: ["Clicked at least 1x", "Did not click"],
  datasets: [
    {
      label: "%",
      data: [62, 38],
      backgroundColor: [CHARTCOLORS[7], CHARTCOLORS[0]],
      borderColor: ["white"],
      borderWidth: 1,
    },
  ],
};

const clicksDistributionData = {
  labels: ["1x", "2x", "3x", "4-6x", "7-10x", ">10x", ">20x"],
  datasets: [
    {
      label: "% Scans",
      data: [13, 19, 17, 5, 22, 9, 19],
      backgroundColor: [
        CHARTCOLORS[0],
        CHARTCOLORS[1],
        CHARTCOLORS[2],
        CHARTCOLORS[3],
        CHARTCOLORS[4],
        CHARTCOLORS[5],
        CHARTCOLORS[6],
      ],
      borderColor: ["white"],
      borderWidth: 1,
    },
  ],
};


const ResalePage = () => {
  return (
    <EngagementPerPageChartsContainer>
      <AverageClicks pageName="Resale Page" avgClicks="2,4" />

      <ChartsWrapper>
        <SingleChartWrapper>
          <p>{"Care Clicks"}</p>
          <PieChartComponent data={clicksData} />
        </SingleChartWrapper>
        <SingleChartWrapper>
          <p>{"Distribution of no. of Clicks(of 62% who scanned)"}</p>
          <PieChartComponent data={clicksDistributionData} />
        </SingleChartWrapper>
      </ChartsWrapper>
    </EngagementPerPageChartsContainer>
  );
};

export default ResalePage;
