export const setPageTitle = ( title ) => {
    document.title = title;
} 

export const getStylePageBreadCrumb = ( links, styleLabel) => {
    const styleLink = {
        url: '/styles',
        label: styleLabel
    }

    const styleLinks = [ styleLink ];

    styleLinks.push(...links);

    return styleLinks;
}