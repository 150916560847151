import { useEffect, useState } from "react";
import BreadCrumb from "../BreadCrumb";
import axios from "../../services/axios";
import { useTranslation } from "react-i18next";
import { getStylePageBreadCrumb } from "../../utils/page-util";

import './index.scss';

const DigitalID = () => {

    const { t } = useTranslation();

    const [ digitalID, setDigitalID ] = useState({
        completed: false,
        data: {}
    });
    const [ links, setLinks ] =  useState([]);

    useEffect(() => {
        axios.get('/api/digital-id')
            .then( response => {
                setDigitalID({
                    completed: true,
                    data: { ...response, elements: response.digitalIDs }
                });
                const pageLinks = [{
                    url: `/styles/${response.parent.sku}`,
                    label: response.parent.name
                },{
                    url: `/styles/${response.parent.sku}/ids`,
                    label: t('digitalIDsLabel')
                },{
                    label: response.id
                }];
                const breadCrumbLinks = getStylePageBreadCrumb(pageLinks, t('styles'));
                setLinks( breadCrumbLinks );
            })
    }, []);

    return digitalID.completed && <div className="component digital-id-cmp">
        <BreadCrumb links={links} />
        <div className="digital-id">
            <h5 className="h5 digital-id__name">
                #{ digitalID.data.id }
            </h5>
            <img src={digitalID.data.src} alt={digitalID.data.id} />
        </div>
    </div>
}

export default DigitalID;