import React, { forwardRef, useImperativeHandle, useState } from "react";
import { useDropzone } from "react-dropzone";
import { UploadWrapper, UploadTextWrapper, Wrapper } from "./addStyles.styles";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getUUID } from "../../services/uuid";

const UploadImage = forwardRef((props, fref) => {
  const [image, setImage] = useState([]);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: { "image/jpeg": [], "image/png": [], "image/gif": [] },
    maxSize: 8242880,
    onDrop: (acceptedFile) => {
      setImage(
        acceptedFile.map((upFile) =>
          Object.assign(upFile, { preview: URL.createObjectURL(upFile) })
        )
      );
    },
  });

  useImperativeHandle(fref, () => ({
    async uploadImageToStorage() {
      try {
        const storage = getStorage();
        const uniqueName = getUUID();
        const storageRef = ref(
          storage,
          `styles/${image[0].name}_${uniqueName}`
        );
        await uploadBytes(storageRef, image[0]);
        const downloadURL = await getDownloadURL(storageRef);
        return downloadURL;
      } catch (error) {
        return "failed";
      }
    },
  }));

  return (
    <Wrapper>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div style={{ display: "flex" }}>
          <div>
            <UploadWrapper>
              <p>Upload Image</p>
            </UploadWrapper>
          </div>
          <UploadTextWrapper>
            <div>
              <p style={{ fontSize: "14px" }}>Press in the box or drag image</p>
              <br></br>
              <p style={{ fontSize: "12px" }}>
                Allowed JPG, GIF or PNG. Max size of 800K
              </p>
            </div>
          </UploadTextWrapper>
        </div>
      </div>
      <div>
        {image.map((upFile) => {
          return (
            <UploadWrapper key={upFile.preview}>
              <img
                src={upFile.preview}
                style={{
                  width: "118px",
                  height: "118px",
                  borderRadius: "10px",
                }}
              />
            </UploadWrapper>
          );
        })}
      </div>
    </Wrapper>
  );
});

export default UploadImage;
