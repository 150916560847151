import { styled } from "../../../stitches.config";

export const ScanNumberContainer = styled("div", {
  marginTop: "20px",
  display: "flex",
});

export const ChartSectionContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  background: "white",
  height: "fit-content",
  width: "100%",
  margin: "20px 0",
  borderRadius: "8px",
  boxShadow: "0px 2px 10px 0px #4C4E6438",
});

export const ChartSectionHeading = styled("div", {
  fontSize: "24px",
  fontWeight: "500",
  fontFamily: "Montserrat",
  marginTop: "20px",
  marginLeft: "20px",
});

export const ChartsWrapper = styled("div", {
  display: "flex",
  margin: "20px 0",
});

export const SingleChartWrapper = styled("div", {
  height: "300px",
  width: "50%",
  textAlign: "center",
  margin: "30px 0",
  p: {
    fontSize: "20px",
    fontWeight: "500",
    marginBottom: "10px",
  },
});
export const FullWidthChartsContainer = styled("div", {
  display: "flex",
  justifyContent:'center',
  margin: "20px 0",
});

export const FullWidthChartWrapper = styled("div", {
  height: "350px",
  width: "60%",
  textAlign: "center",
  margin: "30px 0",
  p: {
    fontSize: "20px",
    fontWeight: "500",
    marginBottom: "10px",
  },
});

export const TimeEngagementWrapper = styled("div", {
  height: "440px",
  width: "50%",
  margin: "0 20px",
});

export const TimeEngagementMainTextWrapper = styled("div", {
  h5: {
    fontSize: "25px",
    fontWeight: "500",
    marginBottom: "10px",
  },
  p: { fontSize: "14px", marginBottom: "10px" },
});

export const TimeEngagementPerPageContainer = styled("div", {
  h5: {
    fontSize: "20px",
    fontWeight: "500",
    margin: "20px 0",
  },
});

export const TimeEngagementPerPageWrapper = styled("div", {
  marginBottom: "22px",
  display: "flex",
  padding: "0 10px",
  alignItems: "center",
  justifyContent: "space-between",
  height: "26px",
  width: "420px",
  borderRadius: "8px",
  boxShadow: "0px 2px 10px rgba(76, 78, 100, 0.3)",
  background: "#FFFFF",
  p: {
    fontSize: "14px",
    fontWeight: "400",
    "&:first-of-type": {
      color: "#22222299",
    },
    "&:nth-of-type(2)": {
      color: "#7D2828",
    },
  },
});

export const EngagementPerPageHeadingWrapper = styled("div", {
  display: "flex",
  margin: "10px 0",
  paddingRight: "20px",
});

export const EngagementPerPageChartsContainer = styled("div", {
  margin: "20px",
});
