import React from "react";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  Typography
} from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { supabase } from '../../supabaseClient';
import { useState } from 'react';

export default function ResetPassword() {

  const [newPassword, setNewPassword] = useState('');
  const [newPasswordConfirm, setNewPasswordConfirm] = useState('');

  const handleResetPassword = async (event) => {
    event.preventDefault();
  
    const { data, error } = await supabase.auth.updateUser({ password: newPassword })
  
    if (error) {
      alert(error.error_description || error.message)
    }else{
      //go to login
      alert("You are in")
    }
  
  }

  return (
      <Card>
        <CardContent>
          <Typography variant="h4" gutterBottom>
            Reset Password
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            color="GrayText"
            mb={2}
          >
            Your new password must be different from previously used passwords
          </Typography>

          <form noValidate autoComplete="off">
            <Box mb={2}>
              <TextField
                label="New Password"
                type="password"
                variant="outlined"
                fullWidth
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Box>
            <Box mb={2}>
              <TextField
                label="Confirm Password"
                type="password"
                variant="outlined"
                fullWidth
                onChange={(e) => setNewPasswordConfirm(e.target.value)}
              />
            </Box>
            <Box mb={2}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#B44A40",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#9a3b34"
                  }
                }}
                onClick={handleResetPassword}
              >
                Login
              </Button>
            </Box>
          </form>
          <Box>
            <Link to={'/'}><Typography
                variant="h6"
                color="#B44A40"
                textAlign="center"
            >
              <ArrowBackIos/>
                Back to login</Typography></Link>
          </Box>
        </CardContent>
      </Card>
  );
}
