import React from "react";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  Typography
} from "@mui/material";
import { ArrowBackIos } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useState } from 'react'
import { supabase } from '../../supabaseClient'


export default function ForgotPassword() {

  const [email, setEmail] = useState("");

  const navigate = useNavigate();
  const handleForgotPassword = async (event) => {
  event.preventDefault();

  const { data, error } = await supabase.auth.resetPasswordForEmail(email, {
    redirectTo: 'https://madi-client.vercel.app/resetpassword',
  })

  if (error) {
    alert(error.error_description || error.message)
  }else{
    alert("Reset password email sent !");
  }

}

  return (
      <Card>
        <CardContent>
          <Typography variant="h4" gutterBottom fontFamily={"Montserrat"}>
            Forgot Password
          </Typography>
          <Typography
            variant="h6"
            color="GrayText"
            mb={2}
            fontFamily={"Montserrat"}
          >
            Enter your email and we'll send you instruction to reset your password
          </Typography>

          <form noValidate autoComplete="off">
            <Box mb={2}>
              <TextField
                label="Email"
                type="email"
                variant="outlined"
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
                fontFamily={"Montserrat"}
              />
            </Box>
            <Box mb={2}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#B44A40",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#9a3b34"
                  },
                  padding: '10px',
                  fontFamily:"Montserrat"
                }}
                
                onClick={handleForgotPassword}
              >
                
                Send Email
              </Button>
            </Box>
          </form>
          <Box>
            <Link to={'/'}><Typography
                variant="h6"
                gutterBottom
                color="#B44A40"
                textAlign="center"
                fontFamily={"Montserrat"}
            >
              <ArrowBackIos/>
                Back to login</Typography></Link>
          </Box>
        </CardContent>
      </Card>
  );
}
