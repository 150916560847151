import FilterItem from "./filter-item";

const Filters = (props) => {
  const { filterList } = props;
  return (
    <div className="filters">
      {filterList.map((style) => (
        <FilterItem key={style.type} {...style} />
      ))}
    </div>
  );
};

export default Filters;
