import './index.scss';

const Typography = () => {
    return <>
        <h1 className='h1'> H1 Headings </h1>
        <h2 className='h2'> H2 Heading </h2>
        <h3 className='h3'> H3 Heading </h3>
        <h4 className='h4'> H4 Heading </h4>
        <h5 className='h5'> H5 Heading </h5>
        <h6 className='h6'> H6 Heading </h6>
        <p className='sub-big'>Subtitle 1</p>
        <p className='sub-small'>Subtitle 2</p>
        <p className='body-big'>Body 1</p>
        <p className='body-small'>Body 2</p>
        <p className='caption'>Caption</p>
        <p className='overline'>Overline</p>
    </>
}

export default Typography;