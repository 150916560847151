import { useTranslation } from "react-i18next";
import MadiButton from "../commons/button";

const DeleteAccount = () => {

    const { t } = useTranslation();

    return <div className="delete setting-block">
        <h6 className='h6'>
            {t('deleteAccount')}
        </h6>
        <p className='sub-small warning'>
            {t('warningInfo')}
        </p>
        <div className="delete__options">
            <MadiButton
                label={t('leaveTeam')}
                type='danger'
            />
            <MadiButton
                label={t('deleteButton')}
                type='secondary'
            />
        </div>
    </div>
}

export default DeleteAccount;