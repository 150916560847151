import React from "react";
import {
  Box,
  Card,
  CardContent,
  Button,
  Typography
} from "@mui/material";

export default function TwoStepVerificationCard() {
  return (
      <Card>
        <CardContent>
          <Typography variant="h4" gutterBottom mb={2}>
            Verify your email
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            color="GrayText"
            mb={2}
          >
            We sent a verification code to your mobile. Enter the code from the mobile in the field below.
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            color="#222222"
            mb={2}
          >
            ******1234
          </Typography>

          <form noValidate autoComplete="off">
            <Box mb={2}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#B44A40",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#9a3b34"
                  }
                }}
              >
                Skip for now
              </Button>
            </Box>
          </form>
          <Box>
            <Typography
                variant="h6"
                gutterBottom
                color="#B44A40"
                textAlign="center"
            >
                Didn't get the email? Resend</Typography>
          </Box>
        </CardContent>
      </Card>
  );
}
