import SideBarItem from "./item";

import './index.scss';
import { Link } from "react-router-dom";

const SIDEBAR_ITEMS = [{
    icon: 'dashboard.svg',
    label: 'Dashboard',
    url: '/dashboard'
},{
    icon: 'season.svg',
    label: 'Season',
    url: '/styles'
},{
    icon: 'styles.svg',
    label: 'Styles',
    url: '/styles'
},{
    icon: 'integrations.svg',
    label: 'Integrations',
    url: '/import-products'
},{
    icon: 'settings.svg',
    label: 'Settings',
    url: '/company-settings/company'
}]

const Sidebar = () => {
    return <div className='component side-bar'>
        <div className='side-bar__holder'>
            <div className='logo'>
                <Link to='/' className='logo__text'>
                    MĀDI
                </Link>
            </div>
            <nav className='nav'>
                <ul className='nav__links'>
                    {SIDEBAR_ITEMS.map( item => {
                        
                        return <li className='nav__links__item' key={item.label}>
                                <SideBarItem  {...item} />
                            </li>
                    })}
                    
                </ul>
            </nav>
        </div>
    </div>
}

export default Sidebar;