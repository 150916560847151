import React from "react";
import {
  Box,
  Card,
  CardContent,
  Button,
  Typography
} from "@mui/material";
import { Link,useNavigate } from "react-router-dom";

export default function VerifyEmailCard(props) {

  const navigate = useNavigate();
  const handleSkipNow = () => {
      navigate('/login');
    
  };


  return (
      <Card sx={{paddingTop:'20px'}}>
        <CardContent>
          <Typography variant="h4" gutterBottom fontFamily={"Montserrat"}>
            Verify your email
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            color="GrayText"
            mb={2}
            fontFamily={"Montserrat"}
          >
            Account activation link sent to your email address: {props.email} Please follow the link inside to continue.
          </Typography>

          <form noValidate autoComplete="off">
            <Box mb={2}>
              <Button
                variant="contained"
                fullWidth
                sx={{
                  backgroundColor: "#B44A40",
                  fontFamily:"Montserrat",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "#9a3b34"
                  },
                  padding:"10px"
                }}
                onClick={handleSkipNow}
              >
                login
              </Button>
            </Box>
          </form>
          {/* <Box>
            <Typography
                variant="h6"
                gutterBottom
                color="#B44A40"
                textAlign="center"
            >
                Didn't get the email? <Link to={'/'}>Resend</Link></Typography>
          </Box> */}
          <Box display="flex" justifyContent={"center"}>
          <Typography color="GrayText" variant="h6" fontFamily={"Montserrat"}>Didn't get the email? </Typography>
          <Link to={'/register'}><Typography color="#B44A40" variant="h6" fontFamily={"Montserrat"}>&nbsp;Resend</Typography></Link>
        </Box>
        </CardContent>
      </Card>
  );
}
