import ProductInformation from "./product-information";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import "./index.scss";
import { useState } from "react";
import DataInsight from "./DataInsight/dataInsight";

const StyleDetailContent = (props) => {
  const { product } = props;
  const [value, setValue] = useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="component style-detail">
      <ProductInformation detail={product} />
      <div style={{ marginTop: "30px" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {/* <Tab label="Detailed Information" style={{ fontSize: "16px" }} /> */}
            <Tab label="Data Insights" style={{ fontSize: "16px" }} />
          </Tabs>
        </Box>
        {value === 0 && (
          <div value={value} index={0}>
            Details to be filled
          </div>
        )}
        {value === 1 && (
          <div value={value} index={1}>
            <DataInsight />
          </div>
        )}
        {/* TODO need tablinks component???? */}
        {/* <TabLinks links={dataLinks} /> */}
      </div>
    </div>
  );
};

export default StyleDetailContent;
