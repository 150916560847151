import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const BarChartComponent = (props) => {
  return (
    <div>
      <Bar
        data={props.data}
        options={props.options}
        //   plugins={[ChartDataLabels]}
      />
    </div>
  );
};

export default BarChartComponent;
