// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import { getStorage } from "firebase/storage";
import "firebase/compat/firestore";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDvwk2toJbUpTu9efoIPR13_IXkApt_nj0",
  authDomain: "madi-software.firebaseapp.com",
  projectId: "madi-software",
  storageBucket: "madi-software.appspot.com",
  messagingSenderId: "243753458819",
  appId: "1:243753458819:web:a20bd4a343fe9b6df34fb6",
  measurementId: "G-3L60DS7734",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
export const db = firebase.firestore();
// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const fs = getFirestore(app);
export const storage = getStorage(app);
export default app;
