import { useTranslation } from "react-i18next";
import TemplatePage from "./page"
import DigitalID from "../components/DigitalID";


const DigitalIDPage = () => {

    const { t } = useTranslation();
 
    return <TemplatePage className='digital-list-page' title={t('styles')}>
        <div className='digital-list-content'>
            <DigitalID />
        </div>
    </TemplatePage>
}

export default DigitalIDPage;