import { useEffect, useState } from "react";
import Actions from "./actions";
import Filters from "./filters";
import "./index.scss";
import axios from "../../services/axios";
import StyleTable from "./table";
import { useTranslation } from "react-i18next";
import { supabase } from "../../supabaseClient";
import { getFilterList, getStylesByOrganization } from "../../services/styles";
import { useUserContext } from "../../store/userContext";
import { useStylesContext } from "../../store/stylesContext";

const StyleListContent = () => {
  const { t } = useTranslation();
  const { getUser } = useUserContext();
  const { saveStyles } = useStylesContext();

  const tableHeading = t("styleHeading", { returnObjects: true });

  const [filterList, setFilterList] = useState([]);
  const [products, setProducts] = useState(null);

  const getStylesFilterList = async () => {
    const data = await getFilterList();
    setFilterList(data);
  };

  const fetchAllProducts = async () => {
    const user = await getUser();
    const styles = await getStylesByOrganization(user.organizationId);
    if (styles.length > 0) {
      setProducts(styles);
      saveStyles(styles);
    }
  };

  useEffect(() => {
    getStylesFilterList();
    fetchAllProducts();
  }, []);

  return (
    <div className="component style-list-cmp">
      <Actions totalProducts={products?.length} />
      <Filters filterList={filterList} />

      <StyleTable tableHeading={tableHeading} productList={products} />
    </div>
  );
};

export default StyleListContent;
