import Option from "./option";

const System = ( props ) => {

    const {
        name,
        description,
        options = []
    } = props;

    return <div className='system'>
        <h5 className='h5'>
            { name }
        </h5>
        <p className='sub-small desc'>
            {description}
        </p>
        <div className='options'>
            { options.map((option, index) => <Option key={index} {...option}/> ) }
        </div>
    </div>

}

export default System;