import { collection, addDoc } from "firebase/firestore";
import { fs, app, db } from "../firebase/firebase.config";
import Qr from "../images/qr.svg";
const COLLECTION_STYLES = "styles";

export const addStyleToDatabase = async (styleToCreate) => {
  try {
    await addDoc(collection(fs, COLLECTION_STYLES), styleToCreate);
    return true;
  } catch (error) {
    return false;
  }
};

export const generateDigitalIds = (maxNumber, style) => {
  const digitalIDArray = [];
  const maxDigits = 4;

  for (let i = 1; i <= maxNumber; i++) {
    const paddedNumber = i.toString().padStart(maxDigits, "0");
    digitalIDArray.push(`${style}${paddedNumber}`);
  }

  return digitalIDArray;
};

export const getStylesByOrganization = async (id) => {
  try {
    const query = db
      .collection(COLLECTION_STYLES)
      .where("organizationId", "==", id);
    const styleSnapShot = await query.get();

    return styleSnapShot.docs.map((doc) => {
      return { ...doc.data(), id: doc.id };
    });
  } catch (error) {
    return false;
  }
};

export const getFilterList = () => {
  const filterList = [
    {
      type: "category",
      options: [
        {
          label: "T-Shirt",
          value: "t-shirt",
        },
      ],
    },
    {
      type: "season",
      options: [
        {
          label: "summer23",
          value: "summer23",
        },
      ],
    },
  ];
  return filterList;
};

export const getDigitalIdTableColumns = () => {
  const columns = [
    {
      field: "tag",
      headerName: "TAG",
      flex: 1,
      renderCell: (params) => <img src={params.value} alt="Image" />,
    },
    {
      field: "digitalId",
      headerName: "DIGITAL ID",
      flex: 1,
    },
    {
      field: "digitalLink",
      headerName: "DIGITAL PASSPORT LINK",
      flex: 2,
      // renderCell: (params) => (
      //   <a href={params.value} target="_blank" rel="noopener noreferrer">
      //     {params.value}
      //   </a>
      // ),
    },
    {
      field: "scans",
      headerName: "SCANS",
      type: "number",
      flex: 1,
    },
    {
      field: "options",
      headerName: "OPTIONS",
      type: "number",
      flex: 1,
    },
  ];
  return columns;
};

export const getDigitalIdTableRows = (ids, url) => {
  const rows = [];
  ids.forEach((id) => {
    rows.push({
      id,
      digitalLink: "sssss",
      digitalId: id,
      scans: 0,
      tag: Qr,
      digitalLink: url + id,
    });
  });
  return rows;
};
