import { useTranslation } from "react-i18next";
import TemplatePage from "./page"
import AccountSettings from "../components/AccountSettings";

const AccountSettingsPage = () => {

    const { t } = useTranslation();
 
    return <TemplatePage className='account-page' title={t('accountSettings')}>
        <div className='account-content'>
            <AccountSettings />
        </div>
    </TemplatePage>
}

export default AccountSettingsPage;