export const getGenders = () => {
  return [
    { value: "m", title: "Male" },
    { value: "f", title: "Female" },
  ];
};

export const getSizeScaling = () => {
  return [
    { value: "INT", title: "International" },
    { value: "FR", title: "France" },
    { value: "US", title: "United States" },
    { value: "UK", title: "United Kingdom" },
    { value: "IT", title: "Italy" },
    { value: "0-5", title: "0-5" },
  ];
};

export const getSizes = (scale) => {
  if (scale === "INT") {
    return [
      { value: "XXXS", title: "XXXS" },
      { value: "XXS", title: "XXS" },
      { value: "XS", title: "XS" },
      { value: "S", title: "S" },
      { value: "M", title: "M" },
      { value: "L", title: "L" },
      { value: "XL", title: "XL" },
      { value: "XXL", title: "XXL" },
      { value: "XXXL", title: "XXXL" },
      { value: "XXXXL", title: "XXXXL" },
    ];
  } else if (scale === "FR") {
    return [
      { value: "30", title: "30" },
      { value: "32", title: "32" },
      { value: "34", title: "34" },
      { value: "36", title: "36" },
      { value: "38", title: "38" },
      { value: "40", title: "40" },
      { value: "42", title: "42" },
      { value: "44", title: "44" },
      { value: "46", title: "46" },
      { value: "48", title: "48" },
    ];
  } else if (scale === "US") {
    return [
      { value: "2", title: "2" },
      { value: "4", title: "4" },
      { value: "6", title: "6" },
      { value: "8", title: "8" },
      { value: "10", title: "10" },
      { value: "12", title: "12" },
      { value: "14", title: "14" },
      { value: "16", title: "16" },
    ];
  } else if (scale === "UK") {
    return [
      { value: "2", title: "2" },
      { value: "4", title: "4" },
      { value: "6", title: "6" },
      { value: "8", title: "8" },
      { value: "10", title: "10" },
      { value: "12", title: "12" },
      { value: "14", title: "14" },
      { value: "16", title: "16" },
      { value: "18", title: "18" },
      { value: "20", title: "20" },
    ];
  } else if (scale === "IT") {
    return [
      { value: "34", title: "34" },
      { value: "36", title: "36" },
      { value: "38", title: "38" },
      { value: "40", title: "40" },
      { value: "42", title: "42" },
      { value: "44", title: "44" },
      { value: "46", title: "46" },
      { value: "48", title: "48" },
      { value: "50", title: "50" },
      { value: "52", title: "52" },
    ];
  } else if (scale === "0-5") {
    return [
      { value: "1", title: "1" },
      { value: "2", title: "2" },
      { value: "3", title: "3" },
      { value: "4", title: "4" },
      { value: "5", title: "5" },
    ];
  } else {
    return [{ value: "", title: "" }];
  }
};

export function formatDateToCustomFormat(date) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const day = ("0" + date.getDate()).slice(-2);
  const monthAbbrev = months[date.getMonth()];
  const year = ("" + date.getFullYear()).slice(-2);

  return `${day}-${monthAbbrev}-${year}`;
}

export const CAREPAGE = "carePage";
export const RESALEAGE = "resalePage";
export const REPAIRPAGE = "repairPage";
export const UPCYCLEPAGE = "upcyclePage";

export function getPages() {
  return [
    { label: "Care Page", value: CAREPAGE },
    { label: "Resale Page", value: RESALEAGE },
    { label: "Repair Page", value: REPAIRPAGE },
    { label: "Upcycle Page", value: UPCYCLEPAGE },
  ];
}
