import React from "react";
import { AverageClicksWrapper } from "./components.styles";

const AverageClicks = ({
  pageName,
  avgClicks,
}: {
  pageName: string;
  avgClicks: string;
}) => {
  return (
    <AverageClicksWrapper>
      <p>{pageName}</p>
      <p>{`${avgClicks} Clicks`}</p>
      <p>Avg. clicks per user</p>
    </AverageClicksWrapper>
  );
};

export default AverageClicks;
