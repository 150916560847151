import { useState } from "react";
import { useTranslation } from "react-i18next";
import MadiTextField from "../commons/text-field";
import MadiButton from "../commons/button";
import InviteMemberModal from "../Onboarding/inviteMemberModal";

const Search = () => {
  const { t } = useTranslation();
  const [isInviteMemberModalOpen, setIsInviteMemberModalOpen] = useState(false);
  const handleInviteCardClick = () => {
    setIsInviteMemberModalOpen(true);
  };
  return (
    <>
      <div className="member search">
        <div className="search__left-block">
          <h6 className="h6">{t("members")}</h6>
          <p className="body-small">{t("membersInfo")}</p>
        </div>
        <div className="search__right-block">
          <MadiTextField tKey="search" />
          <MadiButton
            label={t("inviteMember")}
            type="primary"
            onClick={handleInviteCardClick}
          />
        </div>
        <InviteMemberModal
          isInviteMemberModalOpen={isInviteMemberModalOpen}
          setIsInviteMemberModalOpen={setIsInviteMemberModalOpen}
        />
      </div>
    </>
  );
};

export default Search;
