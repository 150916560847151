import { useEffect, useState } from 'react';
import axios from '../../services/axios';
import DeleteAccount from './delete-account';
import './index.scss';
import Profile from './profile';

const CompanySettings = () => {

    const [ companyInfo, setCompanyInfo ] = useState({
        dataExists: false,
        data: {}
    });

    useEffect( () => {
        axios
            .get('/api/company')
            .then( data => {
                setCompanyInfo({
                    dataExists: true,
                    data
                });
            } )
    }, [] )

    return <div className='component company-settings'>
        { 
        companyInfo.dataExists && 
        <>
            <Profile { ...companyInfo.data }/>
            <DeleteAccount />
        </>
        }
    </div>
}

export default CompanySettings;