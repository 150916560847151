import { useSearchParams } from "react-router-dom";
import VerifyEmailCard from "../components/VerifyEmailCard";
import {Container} from "@mui/material";

const VerifyEmailPage = () => {
    let [searchParams] = useSearchParams(); 
    return     <Container
    maxWidth="xl"
    sx={{
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: "448px"
    }}>
        <VerifyEmailCard email={searchParams.get("email")}/>
    </Container>
  

}

export default VerifyEmailPage;