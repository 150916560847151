import { RouterProvider } from "react-router-dom";
import router, { unauthenticatedRouter } from "./router";
import { useEffect, useState } from "react";
import { supabase } from "./supabaseClient";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../src/firebase/firebase.config";
import { ToastContainer } from "react-toastify";
import { getUserFromDB } from "./services/users";
import { useUserContext } from "./store/userContext";

function App() {
  const [session, setSession] = useState(null);
  const { saveUser, saveSession } = useUserContext();

  // useEffect(() => {
  //   supabase.auth.getSession().then(({ data: { session } }) => {
  //     setSession(session);
  //   });

  //   const {
  //     data: { subscription },
  //   } = supabase.auth.onAuthStateChange((_event, session) => {
  //     setSession(session);
  //   });

  //   return () => subscription.unsubscribe();
  // }, []);

  async function fetchUser() {
    try {
      const tempUser = await getUserFromDB(session);
      saveUser(tempUser);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }

  useEffect(() => {
    onAuthStateChanged(auth, (user) => {
      if (user) {
        const uid = user.uid;
        const user_verified = user.emailVerified;
        console.log("user verified", user.emailVerified);
        if (user_verified) {
          setSession(uid);
          return;
        }
        setSession(null);
      } else {
        setSession(null);
        console.log("user is logged out");
      }
    });
  }, []);

  useEffect(() => {
    console.log("session", session);
    if (session !== null) {
      saveSession(session)
      fetchUser();
    }
  }, [session]);

  return (
    <>
      <ToastContainer />
      <RouterProvider router={session ? router : unauthenticatedRouter} />
    </>
  );
}

export default App;
