import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";

import TabLinks from "../components/TabLinks";
import TemplatePage from "./page";

const SettingsPage = () => {
  const { t } = useTranslation();
  const companyLinks = [
    {
      to: "company",
      key: "company",
    },
    {
      to: "members",
      key: "members",
    },
  ];

  return (
    <TemplatePage className="settings-page" title={t("companySettings")}>
      <div className="settings-content">
        <TabLinks links={companyLinks} />
        <Outlet />
      </div>
    </TemplatePage>
  );
};

export default SettingsPage;
