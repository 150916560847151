import { useTranslation } from "react-i18next";
import TemplatePage from "./page";
import DigitalIDsList from "../components/DigitalIDsList";
import { useParams } from "react-router-dom";

const DigitalIDsPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  return (
    <TemplatePage className="digital-list-page" title={t("styles")}>
      <div className="digital-list-content">
        <DigitalIDsList id={id} />
      </div>
    </TemplatePage>
  );
};

export default DigitalIDsPage;
