import { useTranslation } from "react-i18next";
import MadiButton from "../commons/button";
import { useState } from "react";

const DeleteAccount = () => {

    const { t } = useTranslation();
    const [isDisabled, setIsDisabled] = useState( true );

    const handleOnClick = ( event ) => {
        const { checked } = event.target;
        setIsDisabled( !checked );
    } 
    

    return <div className="delete setting-block">
        <h6 className="h6">
            {t('deleteAccount')}
        </h6>
        <div className='warning-holder'>
            <input className="delete-cb" type="checkbox" id="deleteAccount" onClick={handleOnClick} />
            <label className="sub-small warning" htmlFor="deleteAccount" >
                {t('deleteWarningInfo')}
            </label>
        </div>
        <div className="delete__options">
            <MadiButton
                label={t('deleteAccount')}
                type={ isDisabled ? 'plain' : 'primary' }
                disabled={isDisabled}
            />
        </div>
    </div>
}

export default DeleteAccount;