import React, { useState, useEffect } from "react";
import {
  FormContainer,
  TextFieldElement,
  FormErrorProvider,
  SelectElement,
} from "react-hook-form-mui";
import { useForm } from "react-hook-form";
import {
  Box,
  Button,
  Typography,
  Link as MuiLink,
  Grid,
  Avatar,
  FormControl,
} from "@mui/material";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import "./index.scss";
import {
  createUserWithEmailAndPassword,
  sendEmailVerification,
} from "firebase/auth";
import { auth } from "../../firebase/firebase.config";
import { updateUser, addUserToDatabase } from "../../services/users";
import { getDecoded } from "../../services/base64";

export default function Register() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const [loading, setLoading] = useState(false);
  const [joinTeam, setJoinTeam] = useState(false);
  const [depList, setDepList] = useState([]);

  const { handleSubmit, control, reset, setValue } = useForm({});

  useEffect(() => {
    setValue("firstName", "");
    setValue("companyName", "");
    setValue("jobTitle", "");
    setValue("password", "");
    setValue("lastName", "");
    setValue("phoneNumber", "");
    setValue("country", "");
    setValue("department", "");
    setValue("organizationId", "");
    setValue("password2", "");
    setValue("companyEmail", "");

    const tempJoinTeam = searchParams.get("joinTeam");
    setJoinTeam(tempJoinTeam);
    if (tempJoinTeam) {
      const email = searchParams.get("email");
      const role = searchParams.get("role");
      const id = searchParams.get("id");
      const company = searchParams.get("company");
      const organizationId = searchParams.get("organizationId");
      const country = searchParams.get("country");
      setValue("companyEmail", getDecoded(email));
      setValue("country", country);
      setValue("organizationId", getDecoded(organizationId));
      setValue("role", role);
      setValue("id", getDecoded(id));
      setValue("companyName", getDecoded(company));
    }

    async function fetchDepartments() {
      const { data, error } = await supabase.rpc("get_departments");
      setDepList(data);
    }
    fetchDepartments();
  }, []);

  const navigate = useNavigate();
  const handleRegister = async (data) => {
    setLoading(true);

    await createUserWithEmailAndPassword(auth, data.companyEmail, data.password)
      .then(async (userCredential) => {
        const user = userCredential.user;
        sendEmailVerification(userCredential.user);
        const userDataToUpdate = {
          firstName: data.firstName,
          lastName: data.lastName,
          companyName: data.companyName,
          companyEmail: data.companyEmail,
          phoneNumber: data.phoneNumber,
          organizationId: data.organizationId,
          country: data.country,
          jobTitle: data.jobTitle,
          department: data.department,
          userId: user.uid,
          verified: false,
        };
        if (joinTeam) {
          await updateUser(data.id, userDataToUpdate);
        } else {
          await addUserToDatabase(userDataToUpdate);
        }
        navigate("/");
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage);
        // ..
      });

    // const { error } = await supabase.auth.signUp({
    //   email: data.companyEmail,
    //   password: data.password,
    //   options: {
    //     emailRedirectTo: "https://madi-client.vercel.app/dashboard",
    //     data: {
    //       firstName: data.firstName,
    //       lastName: data.lastName,
    //       companyName: data.companyName,
    //       companyEmail: data.companyEmail,
    //       phoneNumber: data.phoneNumber,
    //       country: data.country,
    //       jobTitle: data.jobTitle,
    //       department: data.department,
    //     },
    //   },
    // });

    // if (error) {
    //   alert(error.error_description || error.message);
    // } else {
    //   //navigate("/verifyemail?email=" + data.companyEmail);
    // }
  };

  var avatarImage = require("../../images/elizabethAndAnnie.png");

  return (
    <Grid container justifyContent="center" py="1.5rem" minHeight="100vh">
      <Grid item xs={12} md={7} width="100%">
        <Box p={12}>
          <Typography
            variant="h4"
            gutterBottom
            className="abc"
            fontFamily={"Montserrat"}
          >
            Get Started
          </Typography>
          <Typography
            variant="h6"
            gutterBottom
            color="GrayText"
            mb={2}
            fontFamily={"Montserrat"}
          >
            Please enter your company details to get started.
          </Typography>

          <FormErrorProvider
            onError={(error) => {
              console.log("you can hook your own error message", error);
              if (error.type === "required") {
                return "This field is required";
              }
              return error?.message;
            }}
          >
            <FormContainer onSuccess={handleSubmit(handleRegister)}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={6}>
                  <Box mb={1}>
                    <TextFieldElement
                      name="firstName"
                      // error={!isFirstNameValid}
                      label="First name"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      control={control}
                      validation={{
                        required: "First name is required",
                        pattern: /^[A-Za-z]+$/i,
                      }}
                      // helperText={firstNameErrorMsg}
                    />
                  </Box>
                  <Box mb={1}>
                    <TextFieldElement
                      disabled={joinTeam}
                      name="companyName"
                      label="Company name"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      control={control}
                    />
                  </Box>
                  <Box mb={1}>
                    <TextFieldElement
                      disabled={joinTeam}
                      name="companyEmail"
                      label="Company email"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      type="email"
                      control={control}
                      validation={{
                        required: "Company email is required",
                        pattern: /^\S+@\S+$/i,
                      }}
                    />
                  </Box>
                  <Box mb={1}>
                    <TextFieldElement
                      name="jobTitle"
                      label="Job title"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      control={control}
                    />
                  </Box>
                  <Box mb={1}>
                    <TextFieldElement
                      name="password"
                      label="Password"
                      type="password"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      control={control}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={6}>
                  <Box mb={1}>
                    <TextFieldElement
                      name="lastName"
                      label="Last name"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      control={control}
                      validation={{
                        required: "Last name is required",
                        pattern: /^[A-Za-z]+$/i,
                      }}
                    />
                  </Box>
                  <Box mb={1}>
                    <TextFieldElement
                      name="phoneNumber"
                      label="Phone number"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                      control={control}
                      validation={{
                        required: "Phone number is required",
                        pattern: /^(?:\+?\d{11})$/i,
                      }}
                    />
                  </Box>
                  <Box mb={1} mt={2}>
                    <FormControl
                      fullWidth
                      defaultValues={{}}
                      onSuccess={() => {}}
                    >
                      <SelectElement
                        control={control}
                        label="Country"
                        name="country"
                        options={[
                          {
                            id: "Sweden",
                            label: "Sweden",
                          },
                          {
                            id: "Denmark",
                            label: "Denmark",
                          },
                          {
                            id: "France",
                            label: "France",
                          },
                          {
                            id: "Italy",
                            label: "Italy",
                          },
                          {
                            id: "United Kingdom",
                            label: "United Kingdom",
                          },
                          {
                            id: "The Netherlands",
                            label: "The Netherlands",
                          },
                          {
                            id: "Spain",
                            label: "Spain",
                          },
                          {
                            id: "United States",
                            label: "United States",
                          },
                        ]}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={1.5} mt={2.5}>
                    <FormControl
                      fullWidth
                      defaultValues={{}}
                      onSuccess={() => {}}
                    >
                      <SelectElement
                        label="Department"
                        name="department"
                        control={control}
                        options={depList}
                      />
                    </FormControl>
                  </Box>
                  <Box mb={1}>
                    <TextFieldElement
                      name="password2"
                      label="Confirm Pasword"
                      type="password"
                      variant="outlined"
                      fullWidth
                      margin="dense"
                    />
                  </Box>
                </Grid>
                <Grid item xs="12" md="12">
                  <Button
                    type={"submit"}
                    variant="contained"
                    fullWidth
                    sx={{
                      backgroundColor: "#B44A40",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#9a3b34",
                      },
                      fontFamily: "Montserrat",
                      padding: "10px",
                    }}
                  >
                    Sign up
                  </Button>
                </Grid>
              </Grid>
            </FormContainer>
          </FormErrorProvider>
          <Box mt={3} display="flex" justifyContent={"center"}>
            <Typography
              variant="h6"
              gutterBottom
              color="GrayText"
              mb={2}
              textAlign="center"
              fontFamily={"Montserrat"}
            >
              Already have an account?{" "}
            </Typography>
            <Link to={"/"}>
              <Typography
                color="#B44A40"
                variant="h6"
                fontFamily={"Montserrat"}
              >
                {" "}
                &nbsp; Login here
              </Typography>
            </Link>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={5}
        backgroundColor="#B44A40"
        sx={{ borderRadius: "1%" }}
      >
        <Box
          height="100%"
          display={"flex"}
          justifyContent={"space-between"}
          flexDirection={"column"}
        >
          <Box>
            <Typography
              variant="h2"
              gutterBottom
              color="#FFFFFF"
              mb={2}
              px={5}
              pt={5}
              fontFamily={"Montserrat"}
            >
              Start your journey with us!
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
              color="#FFFFFF"
              mb={2}
              px={5}
              fontFamily={"Montserrat"}
            >
              Create Digital ID’s for your products, and get insights that can
              help you understand your products better.{" "}
            </Typography>
          </Box>
          <Box
            backgroundColor="rgba(255, 255, 255, 0.2);"
            color="#FFFFFF"
            mx={5}
            mb={5}
            px={4}
            pt={2}
            sx={{ borderRadius: "2%" }}
            width={"380px"}
            height={"240px"}
          >
            <Typography variant="h6" mb={4} fontFamily={"Montserrat"}>
              Welcome to MĀDI! We are excited to partner with you in
              transitioning to a circular fashion system
            </Typography>
            <Typography variant="h5" fontFamily={"Montserrat"}>
              Elizabeth Myers & Annie Hult
            </Typography>
            <Typography variant="h6" mb={2} fontFamily={"Montserrat"}>
              Co-Founders of MĀDI
            </Typography>
            <Avatar
              alt="co-founders"
              sx={{ width: 56, height: 56 }}
              src={avatarImage}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
