import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./index.scss";

const HelpCard = () => {
  const { t } = useTranslation();

  return (
    <div className="help-card">
      <h5 className="h5">{t("helpTitle")}</h5>
      <p
        className="body-big description"
        dangerouslySetInnerHTML={{ __html: t("helpDescription") }}
      />
      <Link to="/contact" className="primary-link">
        {t("contactUS")}
      </Link>
    </div>
  );
};

export default HelpCard;
