import Register from "../components/RegisterCard";
import { Container } from "@mui/material";

const RegisterPage = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Register />
    </Container>
  );
};

export default RegisterPage;
