import System from "./system";

import './index.scss';

const Integrations = ( props ) => {

    const { systems } = props;

    return <div className='component integrations'>
        {
            systems.map( (system, index) => <System key={ index } {...system} /> )
        }
    </div>
}

export default Integrations;