import Axios from "axios";

export const get = async ( apiUrl , headers = {}) => {
    const updatedHeaders = {
        withCredentials: true,
        ...headers
    };
    const url = `${apiUrl}.json`//process.env.NODE_ENV === 'development' ? apiUrl : apiUrl; // replace the else condition with just 'apiUrl', once the backend work is in progress
    const apiResponse = await Axios.get(url, updatedHeaders);
    return apiResponse.data;
};

const axios = {
    get
}

export default axios;