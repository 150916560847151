import { styled } from "../../../stitches.config";


export const Wrapper = styled("div", {
  width: "100%",
  maxWidth: "100%",
  position: "relative",
  marginTop: "0px",
  margin: "7px 0",
  boxSizing: "border-box",
  
});


export const DropDownLabel = styled("div", {
  position: "absolute",
  color: "$textSecondary",
  display: "flex",
  alignItems: "center",
  top: "-8px",
  lineHeight: "16px",
  left: "16px",
  background: "white",
  fontSize: "1.4rem",
  padding: "0 4px",
});

export const DropDownSelect = styled("select", {
  width: "350px",
  lineHeight: "24px",
  padding: "11px 8px",
  border: "1px solid #8F95B2",
  borderRadius: "1rem",
});