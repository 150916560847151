import { styled } from "../../stitches.config";

export const Wrapper = styled("div", {
  display: "flex",
  margin: "20px 0",
});

export const UploadWrapper = styled("div", {
  width: "120px",
  height: "120px",
  border: "1px dotted #222222DE",
  borderRadius: "10px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  p: { textAlign: "center", fontSize: "12px" },
});

export const UploadTextWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  paddingLeft: "40px",
  marginRight: "40px",
});
