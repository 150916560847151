import React from "react";
import {
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { supabase } from "../../supabaseClient";
import { auth } from "../../firebase/firebase.config";
import { signInWithEmailAndPassword } from "firebase/auth";
export default function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleLogin = async (event) => {
    event.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .catch((error) => {
        const errorMessage = error.message;
        console.error(errorMessage);
      })
      .then((res) => {
        console.log('ree',res)
        navigate("/dashboard");
      });
    //   console.log("Pressed");

    //   console.log(email);

    //  const { error } = await supabase.auth.signInWithPassword({ email,password })

    //  if (error) {
    //    alert(error.error_description || error.message)
    //  }else{
    //   console.log("u re in");
    //   navigate('/dashboard');
    //  }
  };

  return (
    <Card>
      <CardContent>
        <Typography
          variant="h4"
          gutterBottom
          textAlign="center"
          fontFamily={"Montserrat"}
        >
          Welcome to MĀDI
        </Typography>
        <Typography
          variant="h6"
          gutterBottom
          textAlign="center"
          color="GrayText"
          mb={2}
          fontFamily={"Montserrat"}
        >
          Please sign-in to our account and start the adventure
        </Typography>

        {/* <Box textAlign="center" mb={2}>
          <Button
            variant="outlined"
            startIcon={<Google />}
            fullWidth
            sx={{
              backgroundColor: "none",
              color: "#6D788D",
              borderColor: "#4C4E6438",
              "&:hover": {
                backgroundColor: "#9a3b34",
              },
            }}
          >
            Continue with google
          </Button>
        </Box> */}

        {/* <Typography variant="body2" gutterBottom textAlign="center" mb={1}>
          OR
        </Typography> */}

        <form noValidate autoComplete="off">
          <Box mb={1}>
            <TextField
              label="Email"
              variant="outlined"
              fullWidth
              onChange={(e) => setEmail(e.target.value)}
            />
          </Box>
          <Box>
            <TextField
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              onChange={(e) => setPassword(e.target.value)}
            />
          </Box>
          <Box mb={2}>
            <Box
              mb={2}
              display="flex"
              justifyContent={"right"}
              alignItems="center"
            >
              {/* <Typography variant="h6" gutterBottom color="GrayText">
                Remember Me
              </Typography> */}
              <Link to={"/forgotpassword"}>
                <Typography
                  variant="h6"
                  textAlign="right"
                  color="#B44A40"
                  fontFamily={"Montserrat"}
                >
                  Forgot Password?
                </Typography>
              </Link>
            </Box>
            <Button
              variant="contained"
              fullWidth
              sx={{
                backgroundColor: "#B44A40",
                color: "white",
                "&:hover": {
                  backgroundColor: "#9a3b34",
                },
                fontFamily: "Montserrat",
                padding: "10px",
              }}
              onClick={handleLogin}
            >
              Login
            </Button>
          </Box>
        </form>
        <Box display="flex" justifyContent={"center"}>
          <Typography color="GrayText" variant="h6" fontFamily={"Montserrat"}>
            New on our platform?{" "}
          </Typography>
          <Link to={"/register"}>
            <Typography color="#B44A40" variant="h6" fontFamily={"Montserrat"}>
              {" "}
              &nbsp; Create an account
            </Typography>
          </Link>
        </Box>
      </CardContent>
    </Card>
  );
}
