import TwoStepVerificationCard from "../components/TwoStepVerificationCard";
import {Container} from "@mui/material";

const TwoStepVerificationPage = () => {

    return     <Container
    maxWidth="xl"
    sx={{
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: "448px"
    }}>
        <TwoStepVerificationCard/>
    </Container>
  

}

export default TwoStepVerificationPage;