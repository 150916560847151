import { useEffect, useState } from "react";
import BreadCrumb from "../BreadCrumb";
import axios from "../../services/axios";
import { useTranslation } from "react-i18next";
import { getStylePageBreadCrumb } from "../../utils/page-util";
import MadiTable from "../commons/table";
import Actions from "./actions";
import "./index.scss";
import { useStylesContext } from "../../store/stylesContext";
import MadiDataGrid from "../commons/dataGrid/dataGrid";
import {
  getDigitalIdTableColumns,
  getDigitalIdTableRows,
} from "../../services/styles";
import { useUserContext } from "../../store/userContext";

const DigitalIDsList = (props) => {
  const { t } = useTranslation();
  const { id } = props;
  const { getStyle } = useStylesContext();
  const { getUser } = useUserContext();
  const [style, setStyle] = useState(null);
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const tempStyle = getStyle(id);
      const user = await getUser();
      const url = `https://app.hellomadi.com/${user.companyName}?style=${tempStyle.styleName}&orgId=${user.organizationId}&styleId=${tempStyle.id}&lang=fr&digitalId=`;
     
      const tempColumns = getDigitalIdTableColumns();
      const tempRows = getDigitalIdTableRows(tempStyle.products, url);
      setRows(tempRows);
      setStyle(tempStyle);
      setColumns(tempColumns);
    }
    fetchData();
  }, []);

  return (
    style && (
      <div className="component digital-ids-cmp">
        <BreadCrumb />
        <div className="digital-ids">
          <Actions name={style.styleName} />
          {/* TODO remove madi table??????????? */}
          {/* <MadiTable
            tKey="digitalIDTable"
            keys={["id", "url", "size"]}
            updateParams={updateQueryParams}
            {...style.products}
          /> */}
          <MadiDataGrid columns={columns} rows={rows} />
        </div>
      </div>
    )
  );
};

export default DigitalIDsList;
