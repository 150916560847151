import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const MadiInputField = forwardRef((props, ref) => {
  const { label, children } = props;

  return (
    <div className="text-field">
      <div className="input-block">
        {children}
        <label className="placeholder">{label}</label>
      </div>
    </div>
  );
});

export default MadiInputField;
