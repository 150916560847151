import { useEffect, useState } from "react";

const ProgressBar = (props) => {
  const { tasks = [], totalTasks } = props;
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const completedTasks = tasks.filter((task) => task.status === "done") || [];
    const width = (completedTasks.length * 100) / totalTasks;

    setWidth(width + "%");
  }, [tasks]);

  return (
    <div className="progress-container">
      <div className="progress">
        <div className="progress__total">
          <div className="progress__completed" style={{ width: width }}></div>
        </div>
        <div className="progress__number">
          <p className="sub-small">{width}</p>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
