import React, { createContext, useContext, useState } from "react";
import { getUserFromDB } from "../services/users";

export const UserContext = createContext();

export const useUserContext = () => useContext(UserContext);

export const UserContextProvider = ({ children }) => {
  const [session, setSession] = useState(null);
  const [user, setUser] = useState(null);

  const saveUser = (user) => {
    setUser(user);
  };

  const saveSession = (s) => {
    setSession(s);
  };

  const getUser = async () => {
    
    if (user == null) {
      console.log('session',session)
      const tempUser = await getUserFromDB(session);
      saveUser(tempUser);
      return tempUser;
    }
    return user;
  };

  return (
    <UserContext.Provider value={{ user, saveUser, getUser, saveSession }}>
      {children}
    </UserContext.Provider>
  );
};
