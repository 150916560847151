import { useEffect, useState } from 'react';
import axios from '../../services/axios';
import DeleteAccount from './delete-account';
import './index.scss';
import Profile from './profile';
import ChangePassword from './change-password';
import NotificationPreferences from './notification-preferences';

const AccountSettings = () => {

    const [ accountInfo, setAccountInfo ] = useState({
        dataExists: false,
        data: {}
    });

    useEffect( () => {
        axios
            .get('/api/account')
            .then( data => {
                setAccountInfo({
                    dataExists: true,
                    data
                });
            } )
    }, [] )

    return <div className='component account-settings'>
        { 
        accountInfo.dataExists && 
        <>
            <Profile { ...accountInfo.data }/>
            <ChangePassword />
            <NotificationPreferences />
            <DeleteAccount />
        </>
        }
    </div>
}

export default AccountSettings;