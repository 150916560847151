import ForgotPasswordCard from "../components/ForgotPasswordCard";
import {Container} from "@mui/material";

const ForgotPasswordPage = () => {

    return     <Container
    maxWidth="xl"
    sx={{
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: "448px"
    }}>
        <ForgotPasswordCard/>
    </Container>
  

}

export default ForgotPasswordPage;