import { createStitches } from "@stitches/react";

export const CHARTCOLORS = [
  "#B44A40",
  "#882516",
  "#B03D01",
  "#661408F7",
  "#B44A4052",
  "#D14343B8",
  "#8225168A",
  "#8F95B2",
];

export const { styled, css, getCssText, keyframes, theme, globalCss } = createStitches(
  {
    theme: {
      colors: {
        warningRed: "#d32f2f",
        textSecondary: "rgba(34, 34, 34, 0.6)",
        chartColor1: CHARTCOLORS[0],
        chartColor2: CHARTCOLORS[1],
        chartColor3: CHARTCOLORS[2],
        chartColor4: CHARTCOLORS[3],
        chartColor5: CHARTCOLORS[4],
        chartColor6: CHARTCOLORS[5],
        chartColor7: CHARTCOLORS[6],
      },
    },
  }
);

export const globalStyles = globalCss({});
