import { useState } from "react";
import MadiButton from "../commons/button";
import "./index.scss";
import { Link } from "react-router-dom";
import { supabase } from "../../supabaseClient";
import {auth} from '../../firebase/firebase.config'
const Header = (props) => {
  const { title } = props;
  const [show, setShow] = useState(false);

  const togglePopUp = () => {
    setShow(!show);
  };

  return (
    <header className="header">
      <div className="header__left">
        <p className="title">{title}</p>
      </div>
      <div className="header__right">
        <img src="/assets/icons/help.svg" alt="" className="img" />
        <MadiButton onClick={togglePopUp}>
          <img src="https://placehold.co/40x40" alt="" className="img avatar" />
        </MadiButton>
        <div className={show ? "popover popover--active" : "popover"}>
          <div className="popover__menu">
            <div className="popover__menu__block">
              <div className="popover__menu__block__item">
                <img src="https://placehold.co/40x40" alt="" />
              </div>
              <div className="popover__menu__block__item">
                <p className="name sub-small">Elizabeth Meyers</p>
                <p className="sub-small">email@email.com</p>
              </div>
            </div>
            <div className="popover__menu__item">
              <img src="/assets/icons/account.svg" alt="" className="img" />
              <Link className="sub-small" to="/account-settings">
                Account Settings
              </Link>
            </div>
            <div className="popover__menu__item">
              <img src="/assets/icons/logout.svg" alt="" className="img" />
              <button
                className="sub-small"
                onClick={() => auth.signOut()}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
