import { styled } from "../../../../stitches.config";

export const ScanNumberWrapper = styled("div", {
  display: "flex",
  background: "white",
  padding: "20px",
  width: "330px",
  marginRight: "30px",
  alignItems: "center",
  borderRadius: "8px",
  boxShadow: "0px 2px 10px 0px #4C4E6438",
  div: {
    marginLeft: "30px",
    p: {
      "&:first-of-type": {
        fontSize: "25px",
        fontWeight: "500",
      },
      "&:nth-of-type(2)": {
        fontSize: "15px",
      },
    },
  },
});

export const AverageClicksWrapper = styled("div", {
  p: {
    "&:first-of-type": {
      fontSize: "22px",
      fontWeight: "500",
    },
    "&:nth-of-type(2)": {
      fontSize: "26px",
      fontWeight: "500",
    },
    "&:nth-of-type(3)": {
      fontSize: "14px",
      fontWeight: "400",
    },
  },
});
