import { Link } from "react-router-dom";

import "./index.scss";

const BreadCrumb = ( props ) => {

    const { links = [] } = props;

    return <div className="component bread-crumb">
        <ul className="bread-crumb__links">
        {
            links.map((link, index) => {
                const { label, url } = link;
                return <li className="li link" key={index} >
                    {
                        url
                        ?
                            <div className="link-holder">
                                <Link to={url} className="link sub-small" >
                                    { label }
                                </Link>
                                <img src="/assets/icons/right-arrow.svg" alt="" />
                            </div>
                        :
                            <div className="text-holder">
                                <span className="sub-small disabled">
                                    {label}
                                </span>
                            </div>
                    }
                </li>
            })
        }
        </ul>
    </div>
}

export default BreadCrumb;