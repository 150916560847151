import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import './index.scss';

const Footer = () => {

    const { t } = useTranslation();

    return <footer className='footer'>
        <div className='footer__left'>
            <p className='body-big title'>
                © 2023 MĀDI ApS
            </p>
        </div>
        <div className='footer__right'>
            <Link to='/contact' className='body-big link-text'>
                {t('contactUS')}
            </Link>
        </div>
    </footer>
}

export default Footer;